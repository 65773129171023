import { useFeatureFlag } from '@/hooks';
import { DynamicPointSize } from '../DynamicPointSize';
import * as state from '@/state';
import { useRecoilValue } from 'recoil';
import { PointSizeSlider } from '../PointSizeSlider';

export const PointSizeControls = () => {
  const isAlphaUser = useFeatureFlag('alpha-user');

  const enableDynamicPointSize = useRecoilValue<boolean>(state.enableDynamicPointSize);

  return (
    <>
      {isAlphaUser && <DynamicPointSize />}

      {!enableDynamicPointSize && (
        <PointSizeSlider title="Point size" step={0.5} min={0} max={10} />
      )}
    </>
  );
};
