import {
  allAssemblyTemplates,
  isCriticalityLevelDialogOpen as isCriticalityLevelDialogOpenState,
  selectedAssemblyId,
  selectedAssemblyTemplateId,
  governingValueByAssemblyId,
} from '@/components/Analysis/state';
import { lazy, Suspense, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Add as AddIcon, ModeEdit as EditIcon, NotificationImportant } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import { useGetAssemblyTemplateFieldDataQuery } from '@/__generated__/graphql';
import { structurePois } from '@/components/Analysis/modules/pointOfInterest/state';
import { CriticalityLevelMapper, Recommendations } from '../common';
import { abyssColors } from '@/theme/colors';
import { joinByRecommendationKey } from '@/utils';
import { useFeatureFlag } from '@/hooks';
import { EquipmentButton } from './styles';

const RecommendationDialogLazy = lazy(() =>
  import('@/components/Analysis/Viewer/Panels/AssemblyTable/Asset/RecommendationDialog').then(
    ({ RecommendationDialog }) => ({
      default: RecommendationDialog,
    })
  )
);

export const CriticalityLevel = () => {
  const [isCriticalityLevelDialogOpen, setisCriticalityLevelDialogOpen] = useRecoilState(
    isCriticalityLevelDialogOpenState
  );
  const nominatedBlister = useRecoilValue(governingValueByAssemblyId);
  const assemblyId = useRecoilValue(selectedAssemblyId);
  const selectedTemplateId = useRecoilValue(selectedAssemblyTemplateId);
  const allTemplates = useRecoilValue(allAssemblyTemplates);
  const allPOIs = useRecoilValue(structurePois);
  const canAccessAitTools = useFeatureFlag(['ait-engineer', 'admin', 'ait-task-lead']);
  const pointOfInterestId = nominatedBlister[assemblyId!]?.pointOfInterest?.id;

  const {
    data: getAssemblyFieldTemplateData,
    loading,
    refetch,
  } = useGetAssemblyTemplateFieldDataQuery({
    variables: { assemblyId: assemblyId ?? '' },
    fetchPolicy: 'no-cache',
    skip: !assemblyId,
  });

  // refetch the templateFieldData if number of pois changes
  useEffect(() => {
    refetch();
  }, [allPOIs, refetch, pointOfInterestId]);

  const templateFieldData = useMemo(() => {
    return getAssemblyFieldTemplateData?.assembly?.templateFieldData ?? {};
  }, [getAssemblyFieldTemplateData?.assembly?.templateFieldData]);

  const template = useMemo(() => {
    if (!selectedTemplateId || !allTemplates) return undefined;
    return allTemplates.find((t) => t.id === selectedTemplateId);
  }, [allTemplates, selectedTemplateId]);

  const recommendation = useMemo(() => {
    if (!template) return;
    const recommendationField = joinByRecommendationKey(template).find(
      (data) => data.displayInQuery
    );
    if (!recommendationField?.key) return;
    if (templateFieldData[recommendationField.key]) {
      const recommendationValue = templateFieldData[recommendationField.key] as Recommendations;
      return CriticalityLevelMapper[recommendationValue];
    }
  }, [template, templateFieldData]);

  const openRecommendationDialog = useCallback(() => {
    setisCriticalityLevelDialogOpen(true);
  }, [setisCriticalityLevelDialogOpen]);

  const closeRecommendationDialog = useCallback(() => {
    setisCriticalityLevelDialogOpen(false);
  }, [setisCriticalityLevelDialogOpen]);

  return (
    <>
      {!loading && templateFieldData && template?.recommendation && assemblyId && (
        <>
          {recommendation ? (
            <Stack direction="row" gap="0.8rem" alignItems="center" my={2}>
              <EquipmentButton
                compact
                disabled={!canAccessAitTools}
                onClick={openRecommendationDialog}
              >
                <NotificationImportant
                  fontSize="small"
                  sx={{
                    marginLeft: '0.4rem',
                    color: recommendation.color ?? abyssColors.error[600],
                    fontSize: '1.4rem',
                  }}
                />
                <span data-testid="criticalityLevelValue">{recommendation.alarmLevel}</span>
                {canAccessAitTools && (
                  <>
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={{ mx: 1, borderColor: abyssColors.primary[500] }}
                    />
                    <EditIcon sx={{ fontSize: '1.4rem' }} />
                  </>
                )}
              </EquipmentButton>
            </Stack>
          ) : (
            <EquipmentButton sx={{ my: 2, maxWidth: '50%' }} onClick={openRecommendationDialog}>
              <AddIcon sx={{ fontSize: '1.4rem' }} />
              <span>{`Add ${template?.recommendation?.header}`}</span>
            </EquipmentButton>
          )}

          {isCriticalityLevelDialogOpen && (
            <Suspense>
              <RecommendationDialogLazy
                isOpen={isCriticalityLevelDialogOpen}
                assemblyId={assemblyId}
                template={template}
                templateFieldData={templateFieldData}
                onClose={closeRecommendationDialog}
              />
            </Suspense>
          )}
        </>
      )}
    </>
  );
};
