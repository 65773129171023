import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Box, TablePagination } from '@mui/material';
import { useState, ReactNode } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TemplateValidationError } from '../types';
import { ErrorCell } from './ErrorCell';
import { pickErrorIconForCell } from '../utils';
import { ErrorLegends } from './IdmsErrorLegends';

type Props = {
  header: string[];
  rows: Array<Record<string, unknown>>;
  errors: Array<TemplateValidationError>;
  footer?: ReactNode;
};

const INITIAL_PAGINATION_PAGE_SIZE = 5;

export function IdmsErrorTable({ header, rows: allRows, errors, footer }: Props) {
  const columns = header.map((current) => ({
    field: current,
    headerName: current,
    width: 150,
  }));

  // 'Rows' only contains rows that have errors
  // Also attach a unique id to each row
  const rows = allRows
    .map((row, index) => ({ id: index, ...row }))
    .filter((row) => {
      const rowError = errors.find((error) => error.line === row.id);
      return !!rowError;
    });

  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_PAGINATION_PAGE_SIZE);

  const [page, setPage] = useState(0);

  const start = page * rowsPerPage;
  const stop = start + rowsPerPage;
  // for when the stop is greater than the length of the rows
  const safeStop = stop > rows.length ? rows.length : stop;

  columns.unshift({ field: 'icons', headerName: '', width: 10 });

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '60vw' }} aria-label="idms table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(start, safeStop).map((row) => {
              const rowError = errors.find((error) => error.line === row.id);
              const errorIcon = rowError ? pickErrorIconForCell({ rowError }) : <></>;

              return (
                <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {errorIcon}
                  </TableCell>
                  {Object.entries(row)
                    .filter(([key]) => key !== 'id')
                    .map(([key, value]) => (
                      <ErrorCell mapKey={key} value={value} errorMap={rowError?.errorMap} />
                    ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length > INITIAL_PAGINATION_PAGE_SIZE && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
          <ErrorLegends />
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPageOptions={[INITIAL_PAGINATION_PAGE_SIZE, 10]}
            page={page}
            onPageChange={(_, value) => {
              setPage(value);
            }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) => {
              const newValue = event.target.value;
              const numericValue =
                typeof newValue === 'string' ? Number.parseInt(newValue, 10) : newValue;
              setRowsPerPage(numericValue);
            }}
          />
        </Box>
      )}
      {footer}
    </Box>
  );
}
