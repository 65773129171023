import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { LoadingButton } from '@/components/shared/LoadingButton';
import { TemplateSelector } from './TemplateSelector/TemplateSelector';
import { FilterActions } from './FilterActions/FilterActions';
import { AssemblyFilters } from './AssemblyFilters/AssemblyFilters';
import { DynamicFilters } from './DynamicFilters/DynamicFilters';
import {
  assemblyTemplate,
  selectedAssemblyTemplateId,
  allAssemblyTemplates,
  applyFilters as applyFiltersState,
} from '@/components/Analysis/state';
import { useHavePermission } from '@/hooks';
import { AssemblyTemplatesQuery, Permissions } from '@/types';
import { primary } from '@/theme/colors';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import {
  TemplateTypeEnum,
  useAllAssemblyTemplatesForFilterSideBarLazyQuery,
} from '@/__generated__/graphql';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';

const FiltersSkeleton = () => {
  return (
    <>
      {Array.from({ length: 8 }, (_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width="100%"
          height={20}
          sx={{ marginTop: '32px' }}
        />
      ))}
    </>
  );
};

type FilterSideBarProps = {
  onClear?: () => void;
  showDynamicFilters?: boolean;
};

export const FilterSideBar = ({ onClear, showDynamicFilters = true }: FilterSideBarProps) => {
  const [value, setValue] = useState(0);
  const [filters, setFilters] = useRecoilState(assemblyTemplate);
  const canReadDefects = useHavePermission(Permissions.READ_DEFECTS);
  const setSelectedTemplateId = useSetRecoilState(selectedAssemblyTemplateId);
  const [allTemplates, setAllAssemblyTemplates] = useRecoilState(allAssemblyTemplates);
  const [applyFilters, setApplyFilters] = useRecoilState(applyFiltersState);
  const structureId = useGetStructureId();
  const type = TemplateTypeEnum.Assembly;
  const { showSnackBar } = useSnackBarMessage({ variant: 'filled' });
  const [queryLoading, setQueryLoading] = useState<boolean>(applyFilters);
  const navbarHeight = useNavbarHeight();

  const [fetchAssemblyTemplatesLazy, { loading }] =
    useAllAssemblyTemplatesForFilterSideBarLazyQuery({
      fetchPolicy: 'cache-first',
    });

  useEffect(() => {
    const doQuery = async () => {
      const { data } = await fetchAssemblyTemplatesLazy({
        variables: {
          structureId,
          type,
        },
        fetchPolicy: 'no-cache',
      });
      const allTemplatesData = data?.allTemplates as AssemblyTemplatesQuery;
      setAllAssemblyTemplates(allTemplatesData);
      if (allTemplatesData?.length > 0) setSelectedTemplateId(allTemplatesData[0].id);
    };

    if (!allTemplates) {
      doQuery();
    }
  }, [
    allTemplates,
    fetchAssemblyTemplatesLazy,
    setAllAssemblyTemplates,
    setSelectedTemplateId,
    structureId,
    type,
  ]);

  useEffect(() => {
    if (!loading && allTemplates) {
      let filteredTemplates = allTemplates[value];
      if (!canReadDefects) {
        filteredTemplates = {
          ...filteredTemplates,
          fields:
            filteredTemplates?.fields?.filter(
              (templateField) =>
                !templateField.permission || templateField.permission !== Permissions.READ_DEFECTS
            ) || [],
        };
      }
      filteredTemplates = {
        ...filteredTemplates,
        fields:
          filteredTemplates?.fields?.filter((templateField) => templateField.displayInQuery) || [],
      };
      setFilters(filteredTemplates);
    }
  }, [canReadDefects, loading, setFilters, value, allTemplates]);

  const handleClick = useCallback(() => {
    setApplyFilters(true);
    setQueryLoading(true);
    showSnackBar('Query has been applied successfully', 'success');
  }, [setApplyFilters, showSnackBar]);

  useEffect(() => {
    if (!applyFilters) {
      setQueryLoading(false);
    }
  }, [applyFilters]);

  return (
    <Stack data-testid="filter-sidebar" sx={{ height: `calc(100vh - ${navbarHeight + 52}px)` }}>
      <Stack>
        <FilterActions onClear={onClear} />
      </Stack>
      {!filters && <FiltersSkeleton />}
      {filters && (
        <Stack
          sx={{
            overflowY: 'auto',

            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <TemplateSelector value={value} setValue={setValue} />
          {showDynamicFilters && <DynamicFilters />}
          <AssemblyFilters value={value} />
        </Stack>
      )}
      {filters && (
        <Stack>
          <LoadingButton
            loading={queryLoading}
            disabled={queryLoading}
            onClick={handleClick}
            buttonVariant="contained"
            buttonStyles={{
              background: primary.darkBlue,
              height: '42px',
              borderRadius: 0.5,
              fontSize: '1.5rem',
              fontWeight: 500,
              mt: 2,
              mb: 2,
              width: '100%',
              p: 1,
              textTransform: 'none',
              '@media (max-width: 1728px)': {
                height: '32px',
                mt: 1,
                mb: 1,
              },
            }}
            buttonText="Apply Query"
          />
        </Stack>
      )}
    </Stack>
  );
};
