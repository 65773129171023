import { abyssColors, primary } from '@/theme/colors';
import { Permissions } from '@/types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Skeleton,
  Button,
  Typography,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Dispatch, useCallback, useMemo, useState } from 'react';
import * as state from '@/components/Analysis/state';
import { CardContainer } from '../../../../Idms/styles';
import { containedButtonStyle } from '@/theme';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { useHavePermission } from '@/hooks';
import {
  DefectPointOfInterestsQuery,
  useAssemblyGoverningValueQuery,
  useDefectPointOfInterestsQuery,
} from '@/__generated__/graphql';
import { NominateDialog } from '@/components/Analysis/modules/pointOfInterest/aitInsights/Defects/NominateDialog';
import { nominateButtonStyles, tableCellStyle } from '../styles';
import { scrollDesign } from '@/components/Analysis/Insights/InsightsExplorer/styles';

const { primary: primaryColors, secondary: secondaryColors } = abyssColors;
const { darkBlue } = primary;

type Props = {
  assemblyId: string | undefined;
  setDefectsCount: Dispatch<React.SetStateAction<number>>;
};
type Poi = DefectPointOfInterestsQuery['allPointOfInterests'][0];
type NominatedPoi = { id: string; name: string } | undefined;

const columns = [
  { id: 'id', label: 'ID', width: 10 },
  { id: 'type', label: 'DEFECT TYPE', width: 210 },
  { id: 'priority', label: 'DC CLASS', width: 80 },
  { id: 'governing', label: 'GOVERNING', width: 200 },
];

export const DefectsTable = ({ assemblyId, setDefectsCount }: Props) => {
  const allPointOfInterestTemplates = useRecoilValue(state.allPointOfInterestTemplates);

  const [isNominateDefectClassOpen, setIsNominateDefectClassOpen] = useState<boolean>(false);
  const [nominatedPoi, setNominatedPoi] = useState<NominatedPoi>(undefined);
  const userCanUpdateAsset = useHavePermission(Permissions.UPDATE_ASSET);

  const { data, loading } = useDefectPointOfInterestsQuery({
    variables: { assemblyIds: [assemblyId || ''], type: 'DEFECT_CLASS' },
    skip: !assemblyId,
  });

  const { data: assemblyQueryResponse, loading: governingPoiLoading } =
    useAssemblyGoverningValueQuery({
      variables: { assemblyId: assemblyId! },
      skip: !assemblyId,
    });

  const governingDefectClassId = useMemo(
    () => assemblyQueryResponse?.assembly?.recommendation?.governingValue?.pointOfInterest?.id,
    [assemblyQueryResponse]
  );

  const defectClassPois = useMemo(() => {
    const defectPois = data?.allPointOfInterests || [];
    setDefectsCount(defectPois.length);
    return defectPois;
  }, [data?.allPointOfInterests, setDefectsCount]);

  const defectClassTemplateFields = useMemo(() => {
    return allPointOfInterestTemplates.find((template) => template.name === 'Defect Class')?.fields;
  }, [allPointOfInterestTemplates]);

  const getFieldValue = useCallback(
    (poi: Poi, key: string) => {
      const fieldId = defectClassTemplateFields?.find((field) => field.name === key)?.id;
      return poi.templateData?.find(({ id }) => id === fieldId)?.value;
    },
    [defectClassTemplateFields]
  );

  const handleNominateClicked = (pointOfInterest: Poi) => {
    if (pointOfInterest.id === governingDefectClassId) return;
    const { id, name, poiId } = pointOfInterest;
    setNominatedPoi({ id, name: name ?? poiId ?? NOT_APPLICABLE_LABEL });
    setIsNominateDefectClassOpen(true);
  };

  return (
    <>
      <Paper elevation={0} sx={{ padding: 1, width: '100%' }}>
        <TableContainer sx={{ width: '100%', ...scrollDesign, overflowX: 'auto' }}>
          {loading || governingPoiLoading ? (
            <CardContainer>
              <Skeleton variant="rectangular" width="100%" height={300} />
            </CardContainer>
          ) : defectClassPois.length > 0 ? (
            <Table sx={{ mb: 1 }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{ ...tableCellStyle, width: column.width, border: 0 }}
                    >
                      <Typography sx={{ fontSize: 10, fontWeight: 700 }}>{column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {defectClassPois.map((poi) => {
                  const isNominated = poi.id === governingDefectClassId;
                  const defectId = poi?.name ?? NOT_APPLICABLE_LABEL;
                  const defectType = getFieldValue(poi, 'Defect Type') ?? NOT_APPLICABLE_LABEL;
                  const dcClass = getFieldValue(poi, 'Priority (Severity)') ?? NOT_APPLICABLE_LABEL;
                  const buttonStyles = {
                    color: isNominated ? secondaryColors[800] : primaryColors[50],
                    backgroundColor: isNominated ? secondaryColors[50] : darkBlue,
                  };
                  return (
                    <TableRow key={poi?.id}>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          width: columns[0].width,
                          cursor: 'pointer',
                          ':hover': { backgroundColor: abyssColors.primary[50] },
                        }}
                      >
                        <Typography sx={{ fontSize: 10, fontWeight: 400 }}>{defectId}</Typography>
                      </TableCell>

                      <TableCell sx={{ ...tableCellStyle, width: columns[1].width }}>
                        <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                          {defectType || 'N/A'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: columns[2].width }}>
                        <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                          {dcClass || 'N/A'}
                        </Typography>
                      </TableCell>
                      {userCanUpdateAsset ? (
                        <TableCell sx={{ ...tableCellStyle, width: columns[3].width }}>
                          <Button
                            onClick={() => handleNominateClicked(poi)}
                            sx={{
                              ...containedButtonStyle,
                              ...nominateButtonStyles,
                              ...buttonStyles,
                              cursor: isNominated ? 'default' : 'pointer',
                              ':hover': { ...buttonStyles },
                            }}
                          >
                            {isNominated ? 'Nominated' : 'Nominate'}
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell sx={{ ...tableCellStyle, width: columns[3].width }}>
                          <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                            {isNominated ? 'Yes' : 'No'}
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
              No Defects found in this assembly
            </Typography>
          )}
        </TableContainer>
      </Paper>
      {isNominateDefectClassOpen && nominatedPoi && (
        <NominateDialog
          isOpen={isNominateDefectClassOpen}
          onClose={() => setIsNominateDefectClassOpen(false)}
          defectClassId={nominatedPoi.id}
          poiName={nominatedPoi.name}
        />
      )}
    </>
  );
};
