import { CameraTargetProps } from '@abyss/3d-viewer';
import queryString from 'query-string';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import * as globalState from '@/state';
import * as analysisState from '@/components/Analysis/state';
import {
  blisterToAdd,
  selectedPointOfInterestId,
} from '@/components/Analysis/modules/pointOfInterest/state';
import { mapLocationToCurrentImage } from './Viewer/hooks/useImageFromLocationId';
import { useGetAssemblyByTagNameLazyQuery } from '@/__generated__/graphql';
import { useGetStructureId } from '@/hooks/useGetStructureId';

export const updateUrl = (key: string, value: string | string[] | undefined | null) => {
  const parsed = queryString.parse(window.location.search, { arrayFormat: 'comma' });

  if (value && value !== '[]') {
    parsed[key] = value;
  } else {
    delete parsed[key];
  }

  const newUrl = `${window.location.pathname}?${queryString.stringify(parsed, {
    arrayFormat: 'comma',
  })}`;

  window.history.replaceState({}, 'Abyss Fabric', newUrl);
};

export const updateCameraTargetUrlParameters = (cameraTarget: CameraTargetProps) => {
  const [lx, ly, lz] = cameraTarget.lookAt;
  updateUrl('look.x', lx.toString());
  updateUrl('look.y', ly.toString());
  updateUrl('look.z', lz.toString());
  const [px, py, pz] = cameraTarget.position ?? [0, 0, 0];
  updateUrl('position.x', px.toString());
  updateUrl('position.y', py.toString());
  updateUrl('position.z', pz.toString());
  updateUrl('fov', cameraTarget.fov ? cameraTarget.fov.toString() : '0');
};

export const useQueryParametersFromState = () => {
  const statesLoaded = useRecoilValue(globalState.statesLoaded);
  const selectedSpherical = useRecoilValue(analysisState.selectedSpherical);
  const selectedStructureDefectsLegends = useRecoilValue(
    analysisState.selectedStructureDefectsLegends
  );
  const selectedAssemblyName = useRecoilValue(analysisState.selectedAssemblyName);
  const pointOfInterestId = useRecoilValue(selectedPointOfInterestId);
  const selectedTemplateFilters = useRecoilValue(analysisState.selectedTemplateFiltersState);
  const cameraTarget: CameraTargetProps | undefined = useRecoilValue(analysisState.cameraTarget);

  useEffect(() => {
    if (statesLoaded) {
      updateUrl(
        'defects',
        selectedStructureDefectsLegends
          ?.filter((defect) => defect.isEnabled === true)
          .map((defect) => JSON.stringify(defect.id))
      );
    }
  }, [selectedStructureDefectsLegends, statesLoaded]);

  useEffect(() => {
    if (statesLoaded) {
      updateUrl('assembly', selectedAssemblyName);
    }
  }, [selectedAssemblyName, statesLoaded]);

  useEffect(() => {
    if (statesLoaded) {
      updateUrl('selectedSpherical', selectedSpherical?.name);
    }
  }, [statesLoaded, selectedSpherical]);

  useEffect(() => {
    if (statesLoaded) {
      updateUrl('poi', pointOfInterestId);
    }
  }, [pointOfInterestId, statesLoaded]);

  useEffect(() => {
    if (statesLoaded) {
      // [{id:'class',o:['Flange','Support']}]
      const filters = selectedTemplateFilters.map((filter) => ({
        id: filter.id,
        o: [...filter.value],
      }));
      updateUrl('filters', JSON.stringify(filters));
    }
  }, [selectedTemplateFilters, statesLoaded]);

  useEffect(() => {
    if (statesLoaded && cameraTarget) {
      updateCameraTargetUrlParameters(cameraTarget);
    }
  }, [cameraTarget, statesLoaded]);
};

export const useSetStateFromQueryParameters = (isInitialPointCloudLoaded: boolean) => {
  const setSelectedSpherical = useSetRecoilState(analysisState.selectedSpherical);
  const structureLocations = useRecoilValue(analysisState.structureLocations);
  const setStatesLoaded = useSetRecoilState(globalState.statesLoaded);
  const setSelectedStructureDefectsLegends = useSetRecoilState(
    analysisState.selectedStructureDefectsLegends
  );
  const setSelectedTemplateFilters = useSetRecoilState(analysisState.selectedTemplateFiltersState);
  const setSelectedAssemblyName = useSetRecoilState(analysisState.selectedAssemblyName);
  const setSelectedAssemblyId = useSetRecoilState(analysisState.selectedAssemblyId);
  const setPointOfInterestId = useSetRecoilState(selectedPointOfInterestId);
  const setBlisterToAdd = useSetRecoilState(blisterToAdd);
  const setAbyssViewerState = useSetRecoilState(globalState.abyssViewerState);

  const setCameraTarget = useSetRecoilState(analysisState.cameraTarget);
  const [getAssemblyId] = useGetAssemblyByTagNameLazyQuery();
  const structureId = useGetStructureId();

  useEffect(() => {
    setStatesLoaded(false);
    if (isInitialPointCloudLoaded) {
      const parsed = queryString.parse(window.location.search, { arrayFormat: 'comma' });
      if (parsed.defects) {
        setSelectedStructureDefectsLegends((currentDefects) => {
          return currentDefects?.map((defect) => {
            if (parsed.defects?.includes(JSON.stringify(defect.id))) {
              return { ...defect, isEnabled: true };
            }
            return defect;
          });
        });
      }
      if (parsed.assembly) {
        const tagName = parsed.assembly.toString();
        if (structureId && tagName) {
          getAssemblyId({ variables: { tagName, structureId } }).then(({ data }) => {
            const assemblyId = data?.assemblyByTagName?.id;
            if (assemblyId) setSelectedAssemblyId(assemblyId);
          });
        }
        setSelectedAssemblyName(tagName);
      }

      if (parsed.poi) {
        setPointOfInterestId(parsed.poi.toString());
        setBlisterToAdd({ state: 'Reviewing' });
      }

      if (
        !parsed.selectedSpherical &&
        parsed['look.x'] &&
        parsed['look.y'] &&
        parsed['look.z'] &&
        parsed['position.x'] &&
        parsed['position.y'] &&
        parsed['position.z'] &&
        parsed.fov
      ) {
        const fov = Number.parseFloat(parsed.fov.toString());
        const cameraTarget: CameraTargetProps = {
          lookAt: [
            Number.parseFloat(parsed['look.x'].toString()),
            Number.parseFloat(parsed['look.y'].toString()),
            Number.parseFloat(parsed['look.z'].toString()),
          ],
          position: [
            Number.parseFloat(parsed['position.x'].toString()),
            Number.parseFloat(parsed['position.y'].toString()),
            Number.parseFloat(parsed['position.z'].toString()),
          ],
          fov: fov > 0 ? fov : undefined,
        };
        setCameraTarget(cameraTarget);
      }

      if (parsed.selectedSpherical) {
        const selectedViewpoint = structureLocations?.find(
          (location) => location.name === parsed.selectedSpherical
        );

        const fov = parsed.fov ? Number.parseFloat(parsed.fov as string) : undefined;
        const lookAt = parsed['look.x']
          ? {
              x: Number.parseFloat(parsed['look.x'] as string),
              y: Number.parseFloat(parsed['look.y'] as string),
              z: Number.parseFloat(parsed['look.z'] as string),
            }
          : undefined;
        setTimeout(() => {
          setAbyssViewerState({ mode: 'Spherical' });
          setSelectedSpherical(mapLocationToCurrentImage(selectedViewpoint, lookAt, fov));
        }, 1000);
      }

      if (parsed.filters) {
        const filters = JSON.parse(parsed.filters.toString());
        const mappedFilteres = filters.map(({ id, o }: { id: string; o: string[] }) => ({
          id,
          value: [...o],
        }));
        setSelectedTemplateFilters(mappedFilteres);
      }

      setStatesLoaded(true);
    }
  }, [
    isInitialPointCloudLoaded,
    structureId,
    getAssemblyId,
    setSelectedAssemblyId,
    setStatesLoaded,
    setSelectedStructureDefectsLegends,
    setSelectedAssemblyName,
    structureLocations,
    setSelectedSpherical,
    setCameraTarget,
    setPointOfInterestId,
    setSelectedTemplateFilters,
    setBlisterToAdd,
    setAbyssViewerState,
  ]);
};
