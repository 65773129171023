import {
  assemblyTemplate as assemblyTemplateState,
  selectedAssemblyId,
  selectedAssemblyTemplateId,
} from '@/components/Analysis/state';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Add as AddIcon,
  ArrowDropDown as DownIcon,
  ArrowDropUp as UpIcon,
} from '@mui/icons-material';
import {
  CircularProgress,
  ButtonBase,
  Divider,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  AssemblyTemplateFieldDataDocument,
  useAssemblyTemplateFieldDataQuery,
  useUpdateCuiPriorityMutation,
} from '@/__generated__/graphql';
import { abyssColors } from '@/theme/colors';
import { useFeatureFlag } from '@/hooks';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { EquipmentButton, ColorIndicator } from './styles';

const defaultPriorityColor = 'black';

export const Priority = () => {
  const assemblyId = useRecoilValue(selectedAssemblyId);
  const assemblyTemplate = useRecoilValue(assemblyTemplateState);
  const templateId = useRecoilValue(selectedAssemblyTemplateId);
  const { showSnackBar } = useSnackBarMessage({ variant: 'filled' });
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>(undefined);
  const canAccessAitTools = useFeatureFlag(['ait-engineer', 'admin', 'ait-task-lead']);
  const structureId = useGetStructureId();

  const isMenuOpen = Boolean(anchorElement);

  const { data, loading } = useAssemblyTemplateFieldDataQuery({
    variables: { assemblyId: assemblyId ?? '' },
    skip: !assemblyId,
  });

  const [updatePriority, { loading: updatePriorityLoading }] = useUpdateCuiPriorityMutation({
    refetchQueries: [AssemblyTemplateFieldDataDocument],
  });

  const priorityField = useMemo(() => {
    return assemblyTemplate?.fields?.find(({ key }) => key === 'cui_priority');
  }, [assemblyTemplate]);

  const assemblyPriority = useMemo(() => {
    return data?.assembly?.templateFieldData?.cui_priority;
  }, [data?.assembly?.templateFieldData]);

  const handleUpdatePriority = async (priority: string) => {
    if (updatePriorityLoading || !assemblyId || !priority || !structureId) return;
    setAnchorElement(undefined);
    try {
      await updatePriority({
        variables: {
          input: {
            assemblyId: assemblyId ?? '',
            structureId: structureId,
            priority: priority,
            templateId: templateId ?? '',
            isManuallyUpdated: true,
            lastModifiedFor: 'CUI Priority Update',
          },
        },
      });
    } catch {
      showSnackBar('Failed to update Priority Level', 'error');
    }
  };

  const dropDownIcon = useMemo(() => {
    const IconComponent = isMenuOpen ? UpIcon : DownIcon;
    return (
      <Icon sx={{ fontSize: 12 }}>
        <IconComponent sx={{ fontSize: 20, mt: '-4px', ml: '-4px' }} />
      </Icon>
    );
  }, [isMenuOpen]);

  const priorityColorsConfig = useMemo(() => {
    return priorityField?.displayConfig?.color;
  }, [priorityField?.displayConfig]);

  if (!priorityField || loading) return <></>;

  return (
    <>
      {assemblyPriority ? (
        <Stack direction="row" gap="0.8rem" alignItems="center" my={2}>
          <Typography fontSize="1.2rem" color={abyssColors.primary[400]} lineHeight="normal">
            CUI Priority
          </Typography>
          <ButtonBase
            disabled={!canAccessAitTools || updatePriorityLoading}
            onClick={(event) => setAnchorElement(event.currentTarget)}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: '1px solid',
                px: 1,
                py: 0.36,
                borderRadius: 1,
                cursor: 'pointer',
                borderColor: abyssColors.primary[500],
              }}
            >
              <ColorIndicator
                sx={{
                  mr: 0.8,
                  backgroundColor:
                    priorityColorsConfig?.find((config) => assemblyPriority === config?.key)
                      ?.value ?? defaultPriorityColor,
                }}
              />
              <Typography fontSize="12px" lineHeight="18px">
                {assemblyPriority}
              </Typography>
              {canAccessAitTools && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ mx: 1, borderColor: abyssColors.primary[500] }}
                  />
                  {updatePriorityLoading ? <CircularProgress size={18} /> : <>{dropDownIcon}</>}
                </>
              )}
            </Stack>
          </ButtonBase>
        </Stack>
      ) : (
        <EquipmentButton sx={{ my: 2 }} onClick={(event) => setAnchorElement(event.currentTarget)}>
          <AddIcon sx={{ fontSize: '1.4rem' }} />
          <span>Add CUI Priority</span>
        </EquipmentButton>
      )}
      {isMenuOpen && (
        <Menu
          anchorEl={anchorElement}
          open={isMenuOpen}
          onClose={() => setAnchorElement(undefined)}
          sx={{ mt: 0.2 }}
        >
          {priorityField.options?.map((option) => (
            <MenuItem
              key={option}
              sx={{ width: 152, py: 0 }}
              onClick={() => handleUpdatePriority(option)}
            >
              <ListItemIcon>
                <ColorIndicator
                  sx={{
                    backgroundColor:
                      priorityColorsConfig?.find((config) => option === config?.key)?.value ??
                      defaultPriorityColor,
                  }}
                />
              </ListItemIcon>
              <ListItemText>{option}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
