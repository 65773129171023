import * as state from '@/state';
import { useRecoilValue } from 'recoil';

export const usePointSize = () => {
  const pointSize = useRecoilValue(state.pointSize);
  const dynamicPointSize = useRecoilValue(state.dynamicPointSize);
  const enableDynamicPointSize = useRecoilValue(state.enableDynamicPointSize);

  return {
    enableSizeAttenuation: enableDynamicPointSize,
    pointSize: enableDynamicPointSize ? dynamicPointSize : pointSize,
  };
};
