import { Box, styled as muiStyled, IconButton, Typography } from '@mui/material';
import { abyssColors } from '@/theme/colors';

export const tooltipPopperStyles = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#000000B8',
    color: abyssColors.primary[50],
    opacity: 1,
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  '& .MuiTooltip-arrow': {
    color: '#000000B8',
  },
};

export const FloatingButton = muiStyled(IconButton)(() => ({
  position: 'fixed',
  bottom: '90px',
  right: '2rem',
  backgroundColor: abyssColors.primary[500],
  color: 'white',
  '&:hover': {
    backgroundColor: abyssColors.primary[600],
  },
}));

export const EndMessage = muiStyled(Typography)(() => ({
  color: abyssColors.primary[300],
  padding: '1.6rem',
  textAlign: 'center',
}));

export const EquipmentHeader = muiStyled(Box)(
  ({ isAtScrollStart }: { isAtScrollStart?: boolean }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 2,
    padding: '1.6rem',
    background: abyssColors.primary[50],
    boxShadow: isAtScrollStart ? 'none' : '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    transition: 'all ease 0.3s',
  })
);

export const EquipmentCardsContainer = muiStyled(Box)(() => ({
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
}));

export const EquipmentTabContainer = muiStyled(Box)(({ topPosition }: { topPosition: number }) => ({
  padding: 0,
  overflowY: 'auto',
  height: `calc(100vh - ${topPosition}px)`,
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
}));

export const ExportButtonContainer = muiStyled(Box)(() => ({
  padding: 16,
  bottom: '0px',
  width: '100%',
  backgroundColor: abyssColors.primary[50],
  position: 'absolute',
}));
