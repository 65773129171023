import { Box, Button, Skeleton } from '@mui/material';
import { useRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import { textButtonStyle } from './styles';
import {
  UnitSystemEnum,
  useGetUserPreferencesForUnitSelectionQuery,
  GetUserPreferencesForUnitSelectionDocument,
  useUpdatePreferencesMutation,
  GetAssembliesByTemplateFieldsFiltersForFilterSideBarDocument,
} from '@/__generated__/graphql';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';

export const UnitSelection = () => {
  const [unitSystem, setSelectedUnit] = useRecoilState(state.unitSystem);
  const { showSnackBar } = useSnackBarMessage({});

  const { loading } = useGetUserPreferencesForUnitSelectionQuery({
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      const currentUnit = data.getUserPreferences?.unit;

      if (currentUnit) {
        setSelectedUnit(currentUnit);
      } else {
        showSnackBar('Failed to retrieve unit system. Please contact support', 'error');
      }
    },
  });
  const [updatePreferencesMutation, { loading: mutationLoading }] = useUpdatePreferencesMutation();

  const handleUpdateUnitSystem = (unit: UnitSystemEnum) => async () => {
    try {
      const response = await updatePreferencesMutation({
        variables: {
          input: {
            unit,
          },
        },
        refetchQueries: [
          GetUserPreferencesForUnitSelectionDocument,
          GetAssembliesByTemplateFieldsFiltersForFilterSideBarDocument,
        ],
      });
      const updatedUnit = response.data?.updateUserPreferences?.preferences?.unit;

      if (updatedUnit) {
        setSelectedUnit(unit);
      } else {
        showSnackBar('Failed to update unit system. Please contact support', 'error');
      }
    } catch {
      showSnackBar('Failed to update unit system. Please contact support', 'error');
    }
  };

  if (loading) {
    return (
      <>
        <Skeleton />
      </>
    );
  }

  const buttonsDisabled = mutationLoading || loading;

  return (
    <>
      <Box sx={{ backgroundColor: '#EDF2F9', borderRadius: '4px', p: 0.5 }}>
        <Button
          variant={unitSystem === UnitSystemEnum.Imperial ? 'contained' : 'text'}
          sx={textButtonStyle}
          onClick={handleUpdateUnitSystem(UnitSystemEnum.Imperial)}
          disabled={buttonsDisabled}
        >
          Imperial
        </Button>
        <Button
          variant={unitSystem === UnitSystemEnum.Metric ? 'contained' : 'text'}
          sx={textButtonStyle}
          onClick={handleUpdateUnitSystem(UnitSystemEnum.Metric)}
          disabled={buttonsDisabled}
        >
          Metric
        </Button>
      </Box>
    </>
  );
};
