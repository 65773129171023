import { Stack } from '@mui/material';
import { OverlayRenderProps } from 'react-photo-view/dist/types';
import { AbyssIcon } from '@/assets/icons/AbyssIcon';

interface MediaViewerToolbarProps {
  disableDelete?: boolean;
  onDownloadClick?: () => void;
  onDeleteClick?: () => void;
  showDownloadIcon?: boolean;
  showDeleteIcon?: boolean;
}

export const MediaViewerToolbar = (
  overlayProps: Partial<OverlayRenderProps & MediaViewerToolbarProps> | undefined
) => {
  return (
    <Stack direction="row" alignItems="center" gap={2} marginX={3}>
      {overlayProps?.onScale && overlayProps?.scale && (
        <AbyssIcon
          iconName="zoom-in"
          pointer
          onClick={() => overlayProps.onScale!(overlayProps.scale! + 1)}
        />
      )}
      {overlayProps?.onScale && overlayProps?.scale && (
        <AbyssIcon
          iconName="zoom-out"
          pointer
          onClick={() => overlayProps.onScale!(overlayProps.scale! - 1)}
        />
      )}
      {overlayProps?.onRotate && overlayProps?.rotate !== undefined && (
        <AbyssIcon
          iconName="rotate-cw"
          pointer
          onClick={() => overlayProps.onRotate!(overlayProps.rotate! + 90)}
        />
      )}
      {overlayProps?.onRotate && overlayProps?.rotate !== undefined && (
        <AbyssIcon
          iconName="rotate-ccw"
          pointer
          onClick={() => overlayProps.onRotate!(overlayProps.rotate! - 90)}
        />
      )}
      {overlayProps?.onDownloadClick && (
        <AbyssIcon
          iconName="download-thin"
          pointer
          onClick={() => {
            overlayProps.onDownloadClick!();
          }}
        />
      )}
      {overlayProps?.onDeleteClick && !overlayProps?.disableDelete && (
        <AbyssIcon
          iconName="delete"
          pointer
          onClick={() => {
            overlayProps.onDeleteClick!();
          }}
        />
      )}
      {overlayProps?.onClose && (
        <AbyssIcon iconName="close" pointer onClick={overlayProps.onClose} />
      )}
    </Stack>
  );
};
