import { AppBar, Button, Grid, Link, Menu, MenuItem, MenuList, Stack } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import React, { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { BugReport, ChromeReaderMode } from '@mui/icons-material';
import { Logo } from './styles';
import { LogInOrOut } from '@/components/shared/LogInOrOut/LogInOrOut';
import { useFeatureFlag } from '@/hooks';
import { REPORT_A_BUG, TAGGING_GUIDE, USAGE_GUIDE } from '@/constants';
import { abyssColors, primary } from '@/theme/colors';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';

type Props = {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.JSX.Element;
};

type InfoMenuItemProps = {
  handleClose: () => void;
  url: string;
  icon: React.ReactNode;
  label: string;
  show: boolean;
};

const menuItemStyles = { px: 3, py: 1.7 };
const linkStyles = { display: 'flex', alignItems: 'center', height: '100%' };
export const iconStyles = { color: primary.darkBlue, mr: 2 };

export const InfoMenuItem = ({ handleClose, url, icon, label, show }: InfoMenuItemProps) => {
  if (!show) {
    return <></>;
  }
  return (
    <MenuItem onClick={handleClose} sx={menuItemStyles}>
      <Link href={url} underline="none" color="inherit" target="_blank" sx={linkStyles}>
        {icon}
        {label}
      </Link>
    </MenuItem>
  );
};

const DefaultLeft: React.ReactNode = (
  <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
    <Link href="/" display="contents">
      <Logo src="/assets/fabric_logo_white.svg" alt="Abyss Fabric" />
    </Link>
  </Stack>
);

export const DefaultRight = () => {
  const router = useRouter();
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>(undefined);
  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(undefined);
  };

  const isTaggingDeployment = useMemo(() => {
    return !router.pathname.includes('analysis') && router.pathname.includes('inspection');
  }, [router.pathname]);

  const canAccessTaggingGuide = useFeatureFlag(['ait-task-lead', 'ait-engineer', 'admin']);

  const menuItems = [
    {
      url: TAGGING_GUIDE,
      icon: <ChromeReaderMode sx={iconStyles} />,
      label: 'Tagging guide',
      show: canAccessTaggingGuide && isTaggingDeployment,
    },
    {
      url: USAGE_GUIDE,
      icon: <ChromeReaderMode sx={iconStyles} />,
      label: 'Usage guide',
      show: !isTaggingDeployment,
    },
    {
      url: REPORT_A_BUG,
      icon: <BugReport sx={iconStyles} />,
      label: 'Report a bug',
      show: true,
    },
  ];

  return (
    <Stack direction="row" spacing={4} justifyContent="flex-end" alignItems="center">
      <Button onClick={handleClick} sx={{ color: 'white', minWidth: 0 }}>
        <HelpOutlineIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList sx={{ p: 0, width: 220 }}>
          {menuItems.map((item) => (
            <InfoMenuItem key={item.label} handleClose={handleClose} {...item} />
          ))}
        </MenuList>
      </Menu>
      <LogInOrOut />
    </Stack>
  );
};
export const Navbar = ({ left = DefaultLeft, center, right = DefaultRight() }: Props) => {
  const navbarHeight = useNavbarHeight();
  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: 'none',
        height: navbarHeight,
        paddingX: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: abyssColors.primary[900],
      }}
      data-testid="navbar-container"
    >
      <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid
          item
          md={3}
          xl={3}
          sx={{
            flexDirection: 'row',
            alignItems: 'stretch',
            display: 'flex',
            pading: 0,
          }}
        >
          {left}
        </Grid>
        <Grid item md={6} xl={6} sx={{ justifyContent: 'space-evenly', display: 'flex', flex: 1 }}>
          {center}
        </Grid>
        <Grid item md={3} xl={3}>
          {right}
        </Grid>
      </Grid>
    </AppBar>
  );
};
