/* eslint-disable no-case-declarations */
import { CSV_TEMPLATE_FREE_TEXT_LABEL, POI_CSV_TEMPLATE_COMMON_HEADERS } from '@/constants';
import { AllPointOfInterestTemplate, TemplateFieldTypeEnum } from '@/types';

type CellData = string | number | boolean | undefined | null;

export type CsvColumn = {
  label: string;
  data: Array<CellData>;
};

export type CSVProcessorConstructor = {
  columns: CsvColumn[];
};

export const stringifyCell = (cell: CellData): string => {
  if (typeof cell === 'string') {
    return cell;
  }
  if (typeof cell === 'number') {
    return cell.toString();
  }
  if (typeof cell === 'boolean') {
    return cell ? 'true' : 'false';
  }
  if (cell === null) {
    return '';
  }
  if (cell === undefined) {
    return '';
  }
  return '';
};

/**
 * Represents a CSV (Comma-Separated Values) processor that provides methods for working with CSV data.
 */
export class CSVProcessor {
  /**
   * An array of CSV columns, each containing a label and an array of data values.
   */
  columns: CsvColumn[] = [];

  /**
   * Creates a new CSVProcessor instance.
   * @param {CSVProcessorConstructor} options - The options to initialize the CSVProcessor with.
   */
  constructor({ columns }: CSVProcessorConstructor) {
    this.columns = columns;
  }

  /**
   * Generates a CSV string representation of the CSV data.
   * @returns {string} The CSV data as a string.
   */
  toString(): string {
    const header = this.columns.map((column) => column.label).join(',');
    const rows = this.columns[0].data.map((_, index) =>
      this.columns
        .map((column) => {
          const cell = column.data[index];
          const stringifiedCell = stringifyCell(cell);
          return stringifiedCell;
        })
        .join(',')
    );
    return [header, ...rows].join('\n');
  }

  /**
   * Initiates the download of the CSV data as a file.
   * @param {string} [filename] - The name of the file to be downloaded (default is "export-{timestamp}.csv").
   */
  download(filename?: string): void {
    const string = this.toString();
    const blob = new Blob([string], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    const generatedFilename = filename || `export-${Date.now().toString()}.csv`;

    link.setAttribute('download', generatedFilename);
    link.style.visibility = 'hidden';
    document.body.append(link);
    link.click();
    link.remove();
  }

  /**
   * Loads new CSV data, replacing the existing columns.
   * @param {CSVProcessorConstructor} options - The options containing new columns to load.
   */
  load({ columns }: CSVProcessorConstructor) {
    this.columns = columns;
  }

  /**
   * Retrieves a specific CSV column by its label.
   * @param {string} label - The label of the column to retrieve.
   * @returns {CsvColumn | undefined} The CSV column with the specified label, or undefined if not found.
   */
  getColumn(label: string): CsvColumn | undefined {
    return this.columns.find((column) => column.label === label);
  }

  /**
   * Adds a new column to the CSV data.
   * @param {string} label - The label for the new column.
   * @param {string[]} data - An array of data values for the new column.
   */
  addColumn(label: string, data: string[]): void {
    this.columns.push({ label, data });
  }

  /**
   * Removes a CSV column by its label.
   * @param {string} label - The label of the column to remove.
   */
  removeColumn(label: string): void {
    this.columns = this.columns.filter((column) => column.label !== label);
  }

  /**
   * Updates the data values of an existing CSV column.
   * @param {string} label - The label of the column to update.
   * @param {string[]} data - The new data values for the column.
   */
  updateColumn(label: string, data: string[]): void {
    const column = this.getColumn(label);
    if (column) {
      column.data = data;
    } else {
      console.error(`Column with label ${label} does not exist`);
    }
  }

  transformColumn(label: string, transform: (data: CellData) => CellData): void {
    const column = this.getColumn(label);
    if (column) {
      column.data = column.data.map(transform);
    } else {
      console.error(`Column with label ${label} does not exist`);
    }
  }

  transformColumns(labels: Array<string>, transform: (data: CellData) => CellData): void {
    labels.forEach((label) => {
      this.transformColumn(label, transform);
    });
  }
}

export class CsvTemplateProcessor {
  private template: AllPointOfInterestTemplate[0];

  headers: string[] = [];

  rows: Array<Record<string, string>> = [];

  constructor(template: AllPointOfInterestTemplate[0]) {
    this.headers = POI_CSV_TEMPLATE_COMMON_HEADERS.map((header) => header.name);
    this.template = template;

    this.template.fields.forEach((field) => {
      this.headers.push(field.name);
    });

    this.populateRows();
  }

  private populateRows = () => {
    this.headers.forEach((header) => {
      const foundCommonColumn = POI_CSV_TEMPLATE_COMMON_HEADERS.find((h) => h.name === header);

      // Check if this header is a common column
      if (foundCommonColumn) {
        this.populateCommonRowCell(foundCommonColumn, header);
      }

      // Otherwise it would be a template column
      else {
        const foundTemplateColumn = this.template.fields.find((field) => field.name === header);
        this.populateTemplateRowCell(foundTemplateColumn, header);
      }
    });
  };

  private populateCommonRowCell = (
    foundCommonColumn: (typeof POI_CSV_TEMPLATE_COMMON_HEADERS)[0],
    header: string
  ) => {
    if (foundCommonColumn?.options) {
      foundCommonColumn?.options?.forEach((option, index) => {
        let copy = this.rows?.[index];
        copy = {
          ...copy,
          // If the field is required
          // add a '(required)' text in the first cell
          // And first cell only
          [header]: foundCommonColumn.required && index === 0 ? `(Required) ${option}` : option,
        };

        this.rows[index] = copy;
      });
    } else {
      let copy = this.rows?.[0];
      copy = {
        ...copy,
        // [header]: foundCommonColumn.helperText,
        [header]: foundCommonColumn.required
          ? `(Required) ${foundCommonColumn.helperText}`
          : foundCommonColumn.helperText,
      };

      this.rows[0] = copy;
    }
  };

  private populateTemplateRowCell = (
    field: AllPointOfInterestTemplate[0]['fields'][0] | undefined,
    header: string
  ) => {
    if (!field) return;

    switch (field.type) {
      case TemplateFieldTypeEnum.SingleSelect:
      case TemplateFieldTypeEnum.RadioButton:
        (field?.options || [])?.forEach((option, index) => {
          let copy = this.rows?.[index];
          copy = {
            ...copy,
            // If the field is required
            // add a '(required)' text in the first cell
            // And first cell only
            [header]: field.isRequired && index === 0 ? `(Required) ${option}` : option,
          };

          this.rows[index] = copy;
        });
        break;

      default:
        let copy = this.rows?.[0];
        copy = {
          ...copy,
          [header]: field?.isRequired
            ? `'(Required)'}${CSV_TEMPLATE_FREE_TEXT_LABEL}`
            : CSV_TEMPLATE_FREE_TEXT_LABEL,
        };

        this.rows[0] = copy;
        break;
    }
  };

  toString = (): string => {
    const header = this.headers.join(',');
    const rows = this.rows.map((row) => {
      return this.headers.map((h) => row[h] || '').join(',');
    });

    return [header, ...rows].join('\n');
  };
}

export const downloadArrayAsCsv = (headers: string[], filename: string) => {
  const csv = new CSVProcessor({
    columns: headers.map((header) => ({ label: header, data: [] })),
  });

  csv.download(filename);
};
