import { Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const ErrorLegentItems = [
  {
    icon: <ErrorIcon sx={{ color: '#CA8A04' }} />,
    text: 'Undefined column',
  },
  {
    icon: <WarningIcon sx={{ color: '#DD0004' }} />,
    text: 'Different values',
  },
  {
    icon: <FileCopyIcon sx={{ color: '#224063' }} />,
    text: 'Value Duplication',
  },
];

export const ErrorLegends = () => {
  return (
    <Stack direction={'row'} gap={2} ml={1}>
      {ErrorLegentItems.map((item) => (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          {item.icon}
          <Typography fontSize={12} fontWeight={400}>
            {item.text}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
