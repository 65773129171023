import { FormLabel, Slider } from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import { useEffect } from 'react';

export const BlendingMode = () => {
  const [blendingOpacity, setBlendingOpacity] = useRecoilState(state.blendOpacity);
  const setBlendOpacityCombined = useSetRecoilState(state.blendOpacityCombined);
  const hasRgb = useRecoilValue(state.hasRgb);

  // Initialize the blending opacity, since it starts from 255 for all modes
  // RGB deployments require the viewer mode to be initialized to 1
  useEffect(() => {
    if (hasRgb) {
      setBlendOpacityCombined({
        viewer: 1,
        assembly: 255,
        spherical: 255,
      });
    }
  }, [setBlendOpacityCombined, hasRgb]);

  const handleToggleRgb = (_: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      return;
    }
    setBlendingOpacity(newValue);
  };

  return (
    <div>
      <FormLabel>Color Blender</FormLabel>
      <Slider
        value={blendingOpacity}
        step={20}
        min={1}
        max={255}
        onChange={handleToggleRgb}
        valueLabelDisplay="auto"
      />
    </div>
  );
};
