export const paginationStyles = {
  '&.MuiTablePagination-root': { borderBottom: 0 },
  '& .MuiTablePagination-toolbar': { p: 0, minHeight: '32px' },
  '& .MuiTablePagination-actions': { m: 0 },
  '& .MuiTablePagination-actions button': { p: 0 },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
    fontSize: '1rem',
  },
  '& .MuiTablePagination-input': {
    fontSize: '1rem',
  },
};
