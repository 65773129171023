import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { assemblyTemplate as assemblyTemplateState } from '@/components/Analysis/state';
import {
  EquipmentDetailTypeEnum,
  OptionFieldTypeEnum,
  EquipmentDetailSubTypeEnum,
  Maybe,
  TemplateFieldTypeEnum,
  EqiupmentCardFieldTypeEnum,
} from '@/__generated__/graphql';
import { getTemplateFieldData } from '@/utils';
import { SUBSTRATE_CONDITION } from '@/constants';
import { Detail, DetailsAccordion } from '../StyledComponents';
import { EquipmentCondition } from '../EquipmentCondition';
import { SelectUpdater } from './OptionFieldDisplays';
import { useUser } from '@auth0/nextjs-auth0';
import { StyledDivider } from '../styles';

type Props = { data: { [key: string]: string }; area: number };

const { SimpleText, CircularProgressBar } = OptionFieldTypeEnum;

const corrosionPriorities = [
  SUBSTRATE_CONDITION.HEAVY,
  SUBSTRATE_CONDITION.MODERATE,
  SUBSTRATE_CONDITION.LIGHT,
];

const calculateSubstrateCondition = (
  corrosionData: {
    name: string | undefined;
    value: Maybe<string> | undefined;
    type:
      | OptionFieldTypeEnum
      | TemplateFieldTypeEnum
      | EqiupmentCardFieldTypeEnum
      | EquipmentDetailTypeEnum
      | undefined;
  }[]
) => {
  if (corrosionData.every((item) => Number(item?.value) === 0)) return SUBSTRATE_CONDITION.CLEAN;
  const corrosion = corrosionData?.filter((item) => Number(item.value) > 0);
  const filteredCorrosion = corrosionPriorities.find((priority) =>
    corrosion.some((item) => item.name === priority)
  );
  return filteredCorrosion || 'N/A';
};

export const EquipmentDetailRenderer = ({ data, area }: Props) => {
  const assemblyTemplate = useRecoilValue(assemblyTemplateState);
  const equipmentDetails = assemblyTemplate.equipmentDetails;
  const { user } = useUser();

  const userRoles = user?.['http://abyss.com/user/roles'] as string[] | undefined;
  const corrosionData = useMemo(() => {
    const corrosionDataList = equipmentDetails
      .filter((field) => field.subType === EquipmentDetailSubTypeEnum.DefectCorrosion)
      .flatMap((list) => list.options ?? []);
    return getTemplateFieldData(corrosionDataList, data);
  }, [equipmentDetails, data, area]);

  const substrateCondition = useMemo(() => {
    return calculateSubstrateCondition(corrosionData);
  }, [corrosionData]);

  const defectMaxSeverity = useMemo(() => {
    return equipmentDetails
      .filter((detailsObject) => detailsObject.type === EquipmentDetailTypeEnum.DefectMaxSeverity)
      .map((detailsObject) => (
        <Detail
          key={detailsObject.type}
          insideAccordion={false}
          title={detailsObject.name}
          value={substrateCondition.toUpperCase()}
        />
      ));
  }, [equipmentDetails, substrateCondition]);

  const defectConsolidatedDegree = useMemo(() => {
    const consolidatedDegreeOfRusting = equipmentDetails.find(
      ({ subType }) => subType === EquipmentDetailSubTypeEnum.DefectConsolidatedDegreeOfRusting
    );
    if (!consolidatedDegreeOfRusting) return <></>;

    const title = data?.consolidated_ri ?? 'N/A';
    const ratio = data?.eq_details_corrosion_ratio ?? 'N/A';

    return (
      <DetailsAccordion title={consolidatedDegreeOfRusting.name}>
        <EquipmentCondition title={title} valueInt={ratio} area={area} />
      </DetailsAccordion>
    );
  }, [equipmentDetails, data, area]);

  const { templateListSimpleText, templateListProgressBar } = useMemo(() => {
    const templateList = equipmentDetails.filter(
      ({ type }) => type === EquipmentDetailTypeEnum.TemplateList
    );

    if (templateList.length === 0) {
      return { templateListSimpleText: [], templateListProgressBar: [] };
    }

    const simpleTextFields = templateList.flatMap((item) => {
      return item.options.filter((option) => option.type === SimpleText);
    });

    const simpleTextFieldsData = getTemplateFieldData(simpleTextFields, data).filter(
      ({ templateKey }) => templateKey !== 'eq_details_nominal_corrosion_allowance'
    );

    const simpleTextComponent = simpleTextFieldsData.map((item, index) => {
      const field = assemblyTemplate?.fields.find((f) => f.key === item.templateKey);
      // Roles stored on the field, if the user has one of these roles, they can edit the field
      // theres also a check on the backend to ensure the user has the correct role
      const editRoles = field?.editRoles;
      const userHasOneOfEditRoles = userRoles?.some((role) => editRoles?.includes(role));
      const shouldShowEditableField = field?.isEditable && userHasOneOfEditRoles;

      const { name, value } = item;

      return (
        <React.Fragment key={index}>
          {shouldShowEditableField && <SelectUpdater {...item} />}
          {!shouldShowEditableField && name && value !== null && value !== undefined && (
            <Detail
              key={name}
              title={name!!}
              value={value === '' ? 'N/A' : value}
              insideAccordion={false}
            />
          )}
        </React.Fragment>
      );
    });

    const progressFieldComponent = templateList
      .filter(({ name }) => name)
      .map((item) => {
        const fields = item.options.filter((option) => option.type === CircularProgressBar);
        const fieldsData = getTemplateFieldData(fields, data);

        return (
          <DetailsAccordion title={item.name} key={item.name}>
            {fieldsData.map((field) => (
              <EquipmentCondition
                key={field.name}
                title={field.name ?? ''}
                valueInt={field.value ?? 0}
                area={area}
              />
            ))}
          </DetailsAccordion>
        );
      });

    return {
      templateListSimpleText: simpleTextComponent,
      templateListProgressBar: progressFieldComponent,
    };
  }, [area, assemblyTemplate?.fields, data, equipmentDetails, userRoles]);

  return (
    <>
      {defectMaxSeverity}
      {templateListSimpleText}
      <StyledDivider />
      {templateListProgressBar}
      {defectConsolidatedDegree}
    </>
  );
};
