import { Box, Button, Divider, Stack, Typography, styled as muiStyled } from '@mui/material';
import styled from 'styled-components';
import { abyssColors } from '@/theme/colors';
import { outlinedButtonStyles } from '@/components/shared/ReportsRevamped/styles';

type ButtonProps = {
  contained?: boolean;
  compact?: boolean;
  disableBorder?: boolean;
};

export const HorizontalBar = styled.div`
  content: '';
  height: 1px;
  background-color: #000;
  min-width: 100%;
  margin: 10px 0 10px 0;
`;

export const EquipmentDetailsTypography = muiStyled(Typography)(() => ({
  fontSize: '1.4rem',
  color: abyssColors.primary[400],
}));

export const BoxStyles = { my: 3 };
export const ViewpointsAccordianStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

export const LineBreak = muiStyled(Box)(() => ({
  width: '100%',
  height: '1px',
  backgroundColor: abyssColors.primary[200],
}));

export const EquipmentDetail = muiStyled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '1.6rem',
}));

export const DetailLabel = muiStyled(Typography)(() => ({
  fontSize: '1.2rem',
  color: abyssColors.primary[400],
  fontWeight: 500,
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const DetailValue = muiStyled(Typography)(() => ({
  fontSize: '1.4rem',
  color: abyssColors.primary[500],
  fontWeight: 500,
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'right',
}));

export const ColorIndicator = muiStyled(Box)(() => ({
  borderRadius: '50%',
  height: '10px',
  width: '10px',
}));

export const ActionButton = muiStyled(Button)(({ contained, compact }: ButtonProps) => ({
  padding: compact ? '0.4rem 0.4rem' : '0.8rem 1.2rem',
  height: '3.4rem',
  borderRadius: '0.4rem',
  fontSize: '1.4rem',
  fontWeight: compact ? 500 : 600,
  letterSpacing: 'normal',
  background: contained ? abyssColors.primary[500] : 'none',
  color: contained ? abyssColors.neutral[50] : abyssColors.primary[500],
  boxShadow: 'none',
  textTransform: 'none',
  width: 'fit-content',
  lineHeight: 'normal',
  '&:disabled': {
    background: contained ? abyssColors.neutral[300] : abyssColors.neutral[50],
    color: contained ? abyssColors.neutral[50] : abyssColors.primary[300],
  },
  '&:hover': {
    background: contained ? abyssColors.primary[400] : abyssColors.primary[100],
  },
  '& span': {
    padding: '0 0.6rem',
  },
}));

export const EquipmentButton = muiStyled(ActionButton)(({ contained, compact }: ButtonProps) => ({
  padding: compact ? '0.4rem 0.4rem' : '0.4rem 0.8rem',
  height: '2.6rem',
  fontSize: '1.2rem',
  border: contained ? 'none' : `0.1rem solid ${abyssColors.primary[500]}`,
  '&:disabled': {
    border: contained ? 'none' : `0.1rem solid ${abyssColors.primary[200]}`,
    background: contained ? abyssColors.neutral[300] : abyssColors.neutral[50],
    color: contained ? abyssColors.neutral[50] : abyssColors.primary[300],
  },
  '&:hover': {
    border: contained ? 'none' : `0.1rem solid ${abyssColors.primary[700]}`,
    background: contained ? abyssColors.primary[400] : abyssColors.primary[100],
  },
  '& span': {
    padding: '0 0.6rem',
  },
}));

export const DialogHeading = muiStyled(Typography)(() => ({
  fontSize: '2rem',
  lineHeight: 'normal',
  fontWeight: 600,
  color: abyssColors.primary[500],
}));

export const tableCellStyle = {
  border: `1px solid ${abyssColors.primary[100]} !important`,
  height: '50px',
  padding: 1,
  textAlign: 'left',
};

export const nominateButtonStyles = {
  fontSize: 10,
  fontWeight: 400,
  padding: '4px 8px',
  width: '80px',
  height: '30px',
  borderRadius: '4px',
  textTransform: 'none',
};

export const cellColorIndicatorStyles = {
  width: 8,
  height: 8,
  borderRadius: '50%',
  marginRight: 0.5,
  mt: 0.25,
};

export const addButtonStyles = {
  ...outlinedButtonStyles,
  px: 1,
  my: 2,
  height: 26,
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 500,
};

export const StyledDivider = muiStyled(Divider)(() => ({
  marginBottom: '8px',
  marginTop: '8px',
  borderColor: abyssColors.primary[300],
}));
