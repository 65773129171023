/* eslint-disable max-classes-per-file */

/*
StateMap helps to maintain large states and to avoid copying state object to update.

Usage:

export const myData = atom<StateMap>({
  default: new StateMap(),
  key: 'myData',
});

// update example
setMyData((oldState)=>{
    const mutableMap = oldState.asMutableStateMap();
    // update mutableMap or mutableMap.data here:
    // mutableMap.data.clear();
    mutableMap.set(123, "abc");
    const name567 = mutableMap.get(567);

    // new state will use same map
    return mutableMap.asStateMap();
})

// read data example
const myData = useState(state.myData);
const name123 = myData.get(123);
myData.forEach((key,value) => { ... });
myData
*/

export class MutableStateMap<T, U> {
  public data: Map<T, U>;

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  private createStateMap: (data: Map<T, U>) => StateMap<T, U>;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    createStateMap: (data: Map<T, U>) => StateMap<T, U>,
    data: Map<T, U> | undefined = undefined
  ) {
    this.data = data ?? new Map<T, U>();
    this.createStateMap = createStateMap;
    // Object.preventExtensions(this);
  }

  get size() {
    return this.data.size;
  }

  has(key: T) {
    return this.data.has(key);
  }

  get(key: T) {
    return this.data.get(key);
  }

  set(key: T, value: U) {
    return this.data.set(key, value);
  }

  setMany(data: [T, U][]) {
    data.forEach((item) => {
      this.data.set(item[0], item[1]);
    });
  }

  delete(key: T) {
    return this.data.delete(key);
  }

  deleteMany(keys: T[]) {
    keys.forEach((key) => {
      this.data.delete(key);
    });
  }

  clear() {
    return this.data.clear();
  }

  keys() {
    return this.data.keys();
  }

  values() {
    return this.data.values();
  }

  entries() {
    return this.data.entries();
  }

  // *[Symbol.iterator]() {
  //   return this.entries();
  // }

  forEach(function_: (value: U, key: T) => void) {
    this.data.forEach(function_);
  }

  asStateMap() {
    // return new StateMap<T, U>(this.data);
    return this.createStateMap(this.data);
  }
}

export class StateMap<T, U> {
  private data: Map<T, U>;

  constructor(data: Map<T, U> | [T, U][] | { [key: string]: U } | undefined = undefined) {
    if (data instanceof Map) {
      this.data = data;
    } else if (Array.isArray(data)) {
      this.data = new Map<T, U>(data);
    } else if (data === undefined) {
      this.data = new Map<T, U>();
    } else {
      const objectData = data as { [key: string]: U };
      const arrayData = Object.keys(objectData).map((current: string) => {
        return [current, objectData[current]];
      });
      // TODO: assert that T === string for type checking
      this.data = new Map<T, U>(arrayData as [T, U][]);
    }
    Object.preventExtensions(this);
  }

  get size() {
    return this.data.size;
  }

  toArray(): U[] {
    return [...this.data.values()];
  }

  has(key: T) {
    return this.data.has(key);
  }

  get(key: T) {
    return this.data.get(key);
  }

  keys() {
    return this.data.keys();
  }

  values() {
    return this.data.values();
  }

  entries() {
    return this.data.entries();
  }

  // *[Symbol.iterator]() {
  //   return this.entries();
  // }

  forEach(function_: (value: U, key: T) => void) {
    this.data.forEach(function_);
  }

  find(function_: (value: U, key: T) => boolean): [U | undefined, T | undefined] {
    let result: [U | undefined, T | undefined] = [undefined, undefined];
    this.data.forEach((value: U, key: T) => {
      if (result[1] === undefined && function_(value, key)) {
        result = [value, key];
      }
    });
    return result;

    // Error: iterators/generators require regenerator-runtime,
    // which is too heavyweight for this guide to allow them. Separately,
    // loops should be avoided in favor of array iterations.  no-restricted-syntax

    // for (const [key, value] of this.data) {
    //   if (function_(value, key)) {
    //     return [value, key];
    //   }
    // }
    // return [undefined, undefined];
  }

  map<ResultItem>(function_: (value: U, key: T) => ResultItem | undefined): ResultItem[] {
    const result: ResultItem[] = [];
    this.data.forEach((value: U, key: T) => {
      const item = function_(value, key);
      if (item !== undefined) {
        result.push(item);
      }
    });
    return result;
  }

  filterValues(function_: (value: U, key: T) => boolean): U[] {
    const result: U[] = [];
    this.data.forEach((value: U, key: T) => {
      if (function_(value, key)) {
        result.push(value);
      }
    });
    return result;
  }

  getMutableDataUnsafe() {
    return this.data;
  }

  asMutableStateMap() {
    const createStateMap = (data: Map<T, U>) => new StateMap<T, U>(data);
    return new MutableStateMap<T, U>(createStateMap, this.data);
  }
}
