import { format, fromUnixTime } from 'date-fns';
import { DEFAULT_CUBE_POSITION, PIT_TYPE } from '@/constants';
import {
  TemplateData,
  TemplateField,
  EquipmentDetailOptionField,
  Maybe,
  EquipmentCardField,
  PoiStatus,
  FileTypes,
  WorkpackStatus,
  TextFormatEnum,
} from '@/__generated__/graphql';
import { AllAssemblyTemplatesForRiskAssessmentQuery } from '@/__generated__/graphql';
import { AssemblyTemplateQuery, Columns, SortableField, SortDirection, SortType } from '@/types';
import { EquipmentDetailField } from '@/__generated__/graphql';
import { Attachment } from '@/components/Analysis/modules/pointOfInterest/PointOfInterestTab/SelectedPoiContent/types';

export const ObjectIdRegex = new RegExp(/^[\dA-Fa-f]{24}$/);

/**
 *
 *  Function is used to join space in strings to _
 *  Primary purpose is to map Icon names to POI_ICON_MAP widget
 * @param name
 * @param splitToken
 * @param byToken
 * @returns Paint Damage -> Paint_Damage
 */
export const joinStringBy = (name: string, splitToken: string, byToken: string) => {
  if (name.length === 0) return name;
  const doesNameHaveSpace = name.split(splitToken).length > 0;
  if (doesNameHaveSpace) {
    return name.split(splitToken).join(byToken);
  }
  return name;
};

export const formatDate = (date: Date): string => {
  return date
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      minute: '2-digit',
      hour: '2-digit',
    })
    .replace(',', '');
};

export function isEmail(email: string): boolean {
  // Regular expression pattern to validate email addresses
  const emailPattern = /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i;

  return emailPattern.test(email);
}

export const convertTemplateNameToPoiType = (name: string | undefined): string => {
  return name?.toUpperCase()?.replaceAll(' ', '_') ?? '';
};

/**
 *
 * @param name Full Name
 * @returns Truncates last name eg Dow Zhong -> Dow Z
 */
export const formatName = (name: string): string => {
  if (!name) {
    return ''; // If name is empty or undefined, return an empty string
  }

  if (isEmail(name)) {
    // split at @ and return the first part
    // i.e john_doe3443@gmail -> john_doe3443
    return name.split('@')[0];
  }

  // Remove leading and trailing whitespace from the name
  const trimmedName = name.trim();

  // Split the trimmed name into an array of name parts using regular expression /\s+/ to handle multiple spaces
  const nameParts = trimmedName.split(/\s+/);

  // If there is only one name part, return the trimmed name as is
  if (nameParts.length === 1) {
    return trimmedName;
  }

  // Get the first name from the name parts array
  const firstName = nameParts[0];

  // Get the last name from the name parts array
  const lastName = nameParts.at(-1);

  // Concatenate the first name and the first character of the last name with a space in between
  return `${firstName} ${lastName?.charAt(0) || ''}`;
};

export const titleCaseSentence = <T>(string: T): T | string => {
  if (!string) {
    return '';
  }
  if (typeof string !== 'string') {
    return string;
  }
  return string.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
};

export const isWordAnAbbreviation = (word: string): boolean => {
  const isASentence = word.includes(' ');
  if (isASentence) return false;

  // Check if the word is all uppercase
  return word === word.toUpperCase();
};

/**
 * Converts a string to title case, handling both single words and sentences.
 *
 * This function takes a string as input and returns a new string where:
 *   - If the input is a single word, it's converted to title case (first letter capitalized, rest lowercase).
 *   - If the input is a sentence (contains spaces), each word is converted to title case, preserving common conjunctions and prepositions in lowercase.
 *
 * This function addresses potential shortcomings of previous implementations by:
 *   - Employing a separate `converter` function for clarity and reusability.
 *   - Accurately handling sentence case by checking for spaces and using `split` appropriately.
 *   - Maintaining consistency in capitalization rules for common words.
 *
 * @param string - The string to be converted to title case.
 * @returns {string} - The converted string in title case.
 *
 * @example
 * ```typescript
 * const singleWord = "hello";
 * const titleCaseSingleWord = toTitleCase(singleWord);
 * console.log(titleCaseSingleWord); // Output: "Hello"
 * ```
 *
 * @example
 * ```typescript
 * const sentence = "this is a sentence with some words";
 * const titleCaseSentence = toTitleCase(sentence);
 * console.log(titleCaseSentence); // Output: "This Is A Sentence With Some Words"
 * ```
 *
 * @example
 * ```typescript
 * const allCaps = "ALL CAPS HERE";
 * const titleCaseAllCaps = toTitleCase(allCaps);
 * console.log(titleCaseAllCaps); // Output: "All Caps Here" (Preserves original casing for non-words)
 * ```
 */
export const toTitleCase = (string: string): string => {
  const converter = (word: string) => {
    const isAbbreviation = isWordAnAbbreviation(word);

    return isAbbreviation ? word : word.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // Checks if its a multi word string
  const isSentence = string.includes(' ');

  if (isSentence) {
    const words = string.split(' ');
    return words.map(converter).join(' ');
  }

  return converter(string);
};

export function arrayEquals<T>(a: T[] | undefined, b: T[] | undefined, strict?: boolean): boolean {
  if (a === undefined && b === undefined) {
    return true;
  }
  if (a!.length === b!.length) {
    return a!.every((element, index) => {
      if (strict ? element === b![index] : b!.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

/**
 *
 * @param string Any string type input
 * @returns string type
 *          UpperCase the first character of the input `string` and lowercase the rest of the `string`
 *          If the input is null or undefined, return itself
 *          eg: LEVEL -> Level
 */
export function titleCase(string: string | null | undefined): string {
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
}

export function getColorIndex(bsonId: string, numberOfColors: number): number | undefined {
  if (ObjectIdRegex.test(bsonId)) {
    const idInteger = Number.parseInt(bsonId.slice(0, 7) + bsonId.slice(18), 16);
    return idInteger % numberOfColors;
  }
  return undefined;
}

// Below panel ids for tagging have drag disabled,
// in future, if you can to disable some panel in tagging, add its panel Id here as well
export const taggingPanelsWithDragDisabled = [
  'isolated_part',
  'zone_selector',
  'visibility',
  'part_selector',
  'comments',
];

export const updateUrlQueryParameters = (key: string, value: string): void => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, '', url.toString());
};

export const formatColor = (selectedColour: number) => {
  return `#${selectedColour.toString(16).slice(0, -2)}`; // formatting number to hex color
};

export const calculateCubePosition = ({
  width,
  height,
  cubeSize,
  padding = 50,
}: {
  width: number;
  height: number;
  cubeSize: number;
  padding?: number;
}): [number, number, number] => {
  if (width === Number.POSITIVE_INFINITY || height === Number.POSITIVE_INFINITY) {
    return DEFAULT_CUBE_POSITION;
  }

  if (width === Number.NEGATIVE_INFINITY || height === Number.NEGATIVE_INFINITY) {
    return DEFAULT_CUBE_POSITION;
  }

  if (width <= 0 || height <= 0) {
    return DEFAULT_CUBE_POSITION;
  }

  const x = width / 2 - cubeSize * 2 - padding;
  const y = height / 2 - cubeSize * 2 - padding;
  const z = 0;
  return [x, y, z];
};

// TODO: to be removed once key value pair flow is implemented
export function getFieldData(
  templateFields: Array<
    Partial<TemplateField | EquipmentDetailOptionField | EquipmentCardField | EquipmentDetailField>
  >,
  templateData: Array<TemplateData>
) {
  return templateFields?.map((field) => {
    const data = templateData?.find((item) => (item.templateFieldId || item.id) === field?.id);
    return {
      name: field?.name,
      value: data?.value,
      type: field?.type,
      position: 'position' in field ? field.position : undefined,
    };
  });
}

export function getTemplateFieldData(
  equipmentCard: Array<Partial<EquipmentDetailOptionField | EquipmentCardField>>,
  templateFieldData: Record<string, string>
) {
  return equipmentCard
    ? equipmentCard
        .map((card) => {
          if (card?.fieldKey) {
            return {
              name: card?.name,
              value: templateFieldData?.[card?.fieldKey] ?? '',
              type: card?.type,
              position: 'position' in card ? card.position : undefined,
              templateKey: card.fieldKey,
            };
          }
          return undefined;
        })
        .filter((item): item is NonNullable<typeof item> => item !== undefined) // type guard, NonNullable takes care of both null and undefined
    : [];
}

export const toFixedTwoDecimalPoints = (decimal: Maybe<string> | undefined): string | number => {
  try {
    if (!decimal) return 0;
    return Number.parseFloat(decimal).toFixed(2);
  } catch {
    return 0;
  }
};

/**
 * @param url: string
 * @returns base64 string
 *         Converts the image url to base64 string
 *        Used for displaying the image in the pdf
 */
export const urlToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url, {
    credentials: 'include',
  });
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.addEventListener('error', reject);
    reader.readAsDataURL(blob);
  });
};

export const splitSort = (currentSort: SortableField) =>
  (currentSort?.split('-') as [SortType, SortDirection]) || [];

/**
 * Gets the next sortable field based on the currentSort and sortType.
 * If currentSort is not provided, it returns the default ascending sorting for the sortType.
 * If currentSort is provided and it's not of the same sortType, it returns the default ascending sorting for the new sortType.
 * If currentSort is provided and it's of the same sortType, it toggles between ascending and descending sorting.
 *
 * @param currentSort The current sorting applied to the field.
 * @param sortType The sortType to which the next sorting should be calculated.
 * @returns The next SortableField after applying the sorting logic, or undefined if sortType is invalid.
 */
export const getNextSort = (currentSort: SortableField, sortType: SortType): SortableField => {
  if (!currentSort) return `${sortType}-asc`;
  const [currentSortType, currentSortDirection] = splitSort(currentSort);
  if (currentSortType !== sortType) return `${sortType}-asc` as SortableField;
  if (currentSortDirection === 'asc') return `${sortType}-desc` as SortableField;
  return undefined;
};

export const isInfinity = (value: number) =>
  value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY;

export const getGoverningValueCalculations = (
  type: string | undefined,
  blisterHeight: number,
  nominalCorrosionAllowance: number,
  nominalThickness: number,
  wallLossFactor?: number | null | undefined
) => {
  const DEFAULT_WALL_LOSS_FACTOR = 6;
  const MILLIMETERS_TO_METERS_FACTOR = 1000;
  const nominalCorrosionAllowanceInMeters =
    nominalCorrosionAllowance / MILLIMETERS_TO_METERS_FACTOR;
  const nominalThicknessInMeters = nominalThickness / MILLIMETERS_TO_METERS_FACTOR;

  const wallLoss =
    blisterHeight < 0 || type === PIT_TYPE
      ? blisterHeight
      : blisterHeight / (wallLossFactor ?? DEFAULT_WALL_LOSS_FACTOR);

  const wallLossPercentage = (wallLoss / nominalThicknessInMeters) * 100;

  const residualCorrosionAllowance = nominalCorrosionAllowanceInMeters - wallLoss;
  const residualCorrosionAllowancePercentage =
    (residualCorrosionAllowance / nominalCorrosionAllowanceInMeters) * 100;

  const residualThickness = nominalThicknessInMeters - wallLoss;
  const residualThicknessPercentage = (residualThickness / nominalThicknessInMeters) * 100;

  return {
    wallLoss: {
      value: wallLoss,
      percentage: isInfinity(wallLossPercentage) ? 0 : wallLossPercentage,
    },
    residualCorrosionAllowance: {
      value: nominalCorrosionAllowanceInMeters ? residualCorrosionAllowance : 0,
      percentage: isInfinity(residualCorrosionAllowancePercentage)
        ? 0
        : residualCorrosionAllowancePercentage,
    },
    residualThickness: {
      value: nominalThicknessInMeters ? residualThickness : 0,
      percentage: isInfinity(residualThicknessPercentage) ? 0 : residualThicknessPercentage,
    },
  };
};

/**
 * Fetches the second last item in an array, if it exists.
 * If the second last item doesn't exist, it returns the last item.
 *
 * @param array - The input array from which to retrieve the last non-null and non-undefined item.
 * @returns The last non-null and non-undefined item from the array, or undefined if no such item exists.
 *
 * @example
 * const array = [1,2,3,4]
 * const lastItem = getLastArrayItems(array); // Returns 3
 *
 */
export const getLastArrayItems = <T>(array: T[] | undefined): T | undefined => {
  if (!Array.isArray(array) || !array?.length || array?.length === 0) {
    return undefined;
  }

  // if all items are falsy, return undefined
  if (array.every((item) => !item)) {
    return undefined;
  }

  // try to get the second last item, if it doesn't exist, get the last item
  // otherwise return undefined
  return array?.[array.length - 2] ?? array?.[array.length - 1] ?? undefined;
};

/**
 * Gets the last two items from the array.
 *
 * @param array - The input array from which to retrieve the last two items.
 * @returns An array containing the last two items from the input array, or undefined if there are not enough items.
 *
 * @example
 * const array = [10, 20, 30, 40];
 * const lastTwoItems = getLastTwoArrayItems(array); // Returns [30, 40]
 *
 * const array = [1]
 * const lastTwoItems = getLastTwoArrayItems(array); // Returns [1]
 */
export const getLastTwoArrayItems = <T>(array: T[] | undefined): T[] | undefined => {
  const arrayLength = array?.length;

  if (!arrayLength) return undefined;

  if (arrayLength === 0) return undefined;
  if (arrayLength === 1) return [array[0]];
  return [array[arrayLength - 2], array[arrayLength - 1]];
};

/**
 * Deletes the specified query parameter from the current URL's query string
 * and updates the browser's history with the modified URL.
 *
 * @param parameterName - The name of the query parameter to be removed from the URL.
 *
 * @example
 * // Assuming the current URL is "https://example.com/page?foo=123&bar=456"
 * deleteQueryParameterFromUrl("foo");
 * // The URL will be modified to "https://example.com/page?bar=456"
 */
export const deleteQueryParameterFromUrl = (parameterName: string) => {
  const url = new URL(window.location.href);
  const parameters = new URLSearchParams(url.search);
  parameters.delete(parameterName);
  url.search = parameters.toString();
  window.history.replaceState({}, '', url.toString());
};

export const calculatePercentage = (area: number, value: number) => {
  try {
    return Math.round((value / area) * 100);
  } catch {
    return 0;
  }
};

export const clamp = (value: number, minValue: number, maxValue: number): number => {
  return Math.max(Math.min(value, maxValue), minValue);
};

export const toHexString = (n: number, resultLength: number) => {
  return Math.trunc(n).toString(16).toLowerCase().padStart(resultLength, '0');
};

export const colorWithAlpha = (color: number, blendOpacity: number): number => {
  const alpha = clamp(Math.trunc(blendOpacity), 0, 255);
  // eslint-disable-next-line no-bitwise
  return (color & 0xffffff00) | (alpha & 0xff);
};

export const convertColorHexStringToNumber = (colorString: string): number => {
  switch (colorString.length) {
    case 9:
      // '#11223344'
      return Number.parseInt(colorString.slice(1), 16);
    case 7:
      // '#112233'
      return Number.parseInt(colorString.slice(1), 16) * 256 + 255;
    default:
      throw new Error(`Unsupported color string '${colorString}'`);
  }
};

export const convertRgbaToRgb = (rgba: number): number => {
  return Math.trunc(rgba / 0x100);
};

export const convertRgbToRgba = (rgb: number, alpha: number = 0xff): number => {
  return rgb * 0x100 + alpha;
};

export const convertRgbToColorHexString = (rgb: number): string => {
  return `#${toHexString(rgb, 6)}`;
};

export const convertRgbaToColorHexString = (rgba: number): string => {
  return `#${toHexString(rgba, 8)}`;
};

export const hexColorWithAlpha = (color: string, alpha: number): string => {
  const number = convertColorHexStringToNumber(color);
  const rgba = colorWithAlpha(number, alpha);
  return convertRgbaToColorHexString(rgba);
};

export const hexToRgba = (hex: string, a = 0.5) => {
  const [r, g, b] = [0, 2, 4].map((index) => Number.parseInt(hex.slice(index + 1, index + 3), 16));
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const getEquipmentColumnsFromAssemblyTemplate = (
  assemblyTemplate:
    | AssemblyTemplateQuery
    | AllAssemblyTemplatesForRiskAssessmentQuery['allTemplates'][0]
): Columns[] => {
  return assemblyTemplate.fields.map(
    (
      {
        id,
        name,
        displayConfig,
        key,
        displayInQuery,
        displayInRisk,
        roundToDecimal,
        unit,
        displayUnit,
      },
      index
    ) => ({
      isSortable: displayConfig?.isSortable || false,
      visibility: index < 4, // initially only 3 dynamic should be visible in table
      key: id,
      templateFieldKey: key ?? '',
      sortType: displayConfig?.sortType,
      columnName: name,
      isTemplateField: true,
      displayConfig: { color: displayConfig?.color ?? undefined, type: displayConfig?.type },
      displayInQuery,
      displayInRisk,
      roundToDecimal,
      unit,
      displayUnit,
    })
  );
};

export const csvSafeFunction = <T>(value: Maybe<T>): T | undefined => {
  const hasComma = typeof value === 'string' && value?.includes(',');
  return hasComma ? (`"${value}"` as T) : (value as T);
};

export const removeMaybeTypeFromValue = <T>(value: Maybe<T>): T | undefined => {
  if (!value) return undefined;
  return value;
};

export const isNullOrUndefined = <T>(value: T | null | undefined): value is null | undefined => {
  return value === null || value === undefined;
};

export const roundFloat = (value: number, decimalPlaces: number) => {
  return value.toFixed(decimalPlaces);
};

export const formatTimeStamps = (timeStamp: string | number, dateFormat: string) => {
  const converetdTimeStamp = typeof timeStamp === 'string' ? Number(timeStamp) : timeStamp;
  const date = format(fromUnixTime(converetdTimeStamp / 1000), dateFormat);
  return date;
};

export const joinByRecommendationKey = (template: AssemblyTemplateQuery) => {
  return template?.fields.filter((field) => {
    const isKeyFoundInRecomendation = template?.recommendation?.details.find((detail) => {
      return detail?.fieldKey === field.key;
    });
    return isKeyFoundInRecomendation;
  });
};

export const formatRecommendationHeader = (header: string, isUpdateMode: boolean) => {
  const updateVerb = isUpdateMode ? 'Update' : 'Add';
  return `${updateVerb} ${header}`;
};

/**
 * Checks if a string is undefined, empty, or contains only whitespace.
 *
 * @param {string | undefined} value - The string to be checked.
 * @returns {boolean} Returns true if the string is undefined, empty, or contains only whitespace; otherwise, returns false.
 *
 * @example
 * const result = checkUndefinedString("   "); // Returns true
 * const result = checkUndefinedString("Hello World"); // Returns false
 * const result = checkUndefinedString(undefined); // Returns true
 */
export const isStringFalsy = (value: string | undefined) => {
  if (value === undefined) {
    return true;
  }

  // if string does not contain any characters or only contains whitespace
  const trimmedValue = value.replace(/\s/g, '');

  if (trimmedValue === '') {
    return true;
  }

  return value === 'undefined';
};

export const extractStatusCodeFromApolloResponse = (response: unknown): number | undefined => {
  if (!response || typeof response !== 'object' || !('statusCode' in response)) {
    return undefined;
  }

  const numericStatusCode = Number(response.statusCode);
  return Number.isNaN(numericStatusCode) ? undefined : numericStatusCode;
};

export const convertTimeStampToDate = (timeStamp: Maybe<string> | undefined): Date | undefined => {
  if (!timeStamp) return undefined;
  return new Date(Number(timeStamp));
};
const PoiStatusFormatter: Record<PoiStatus, string | undefined> = {
  [PoiStatus.Completed]: 'Completed',
  [PoiStatus.Inprogress]: 'In Progress',
  [PoiStatus.Remediated]: 'Remediated',
  [PoiStatus.Inreview]: 'In Review',
  [PoiStatus.ChaseItemAdded]: 'Chase Item Added',
};

// TODO: Replace with generic function after enums are fixed on the backend
export const formatPoiStatus = (status: PoiStatus | WorkpackStatus) => {
  // PoiStatus and WorkpackStatus are same for now, once they are different, this function will be updated
  if (!status) return 'No Status';
  return PoiStatusFormatter[status] || '';
};

/**
 *
 * @param string Any string type input
 * @returns string type
 *          Replaces underscores with spaces and UpperCase the first character of the each word in input `string` and lowercase the rest of the word
 *          If the input is null or undefined, return itself
 *          eg: LEVEL_TWO -> Level Two
 */
export function formatEnumForDisplay(string: string | null | undefined): string {
  return string ? titleCaseSentence(string.replaceAll('_', ' ')) : '';
}

/**
 *
 * @param string Any string type input
 * @returns string type
 *          Replaces underscores with spaces and UpperCase the first character of each word in input `string` and lowercase the rest of the word
 *          If the input is null or undefined, return itself
 *          eg: LEVEL_TWO -> Level Two or LevelTwo -> Level Two
 */
export function formatKeyForDisplay(string: string | null | undefined): string {
  if (!string) return '';

  // Replace underscores with spaces and handle camel case
  const formattedString = string.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2');

  return titleCaseSentence(formattedString);
}

/**
 *
 * @param string Any string type input
 * @returns string type
 *          Removes all the spaces in the string and then convert to upper case
 *          eg: Level Two -> LEVELTWO
 */
export function formatRemoveSpaceUpperCase(string: string | null | undefined): string {
  return string ? string.replace(/\s+/g, '').toLocaleUpperCase() : '';
}

/**
 *
 * @param string Any string type input
 * @returns string type
 *          Replaces space with underscores and UpperCases the entire string
 *          If the input is null or undefined, return itself
 *          eg: Level Two -> LEVEL_TWO
 */
export function upperSnakeCase(string: string | null | undefined): string {
  return string ? string.replaceAll(' ', '_').toLocaleUpperCase() : '';
}

export const formatDropdownValue = (
  value: string | undefined | null,
  valueFormat: Maybe<TextFormatEnum>,
  defaultValue = 'N/A'
) => {
  if (!value) return defaultValue;

  // Format PoiStatus with mapping
  const poiStatus = formatPoiStatus(value as PoiStatus);
  if (poiStatus) return poiStatus;

  switch (valueFormat) {
    case TextFormatEnum.None:
      return value;
    case TextFormatEnum.UpperCase:
      return value.toUpperCase();
    case TextFormatEnum.LowerCase:
      return value.toLowerCase();
    case TextFormatEnum.TitleCase:
      return titleCase(value);
    default:
      return value;
  }
};

/*
Function that takes an object and sets all properties to false
except for the provided property, which will be toggled false if true, true if false.
*/
export function toggleValue<T>(drawerState: T, property: keyof T): T {
  const newState: Partial<T> = { ...drawerState };
  (Object.keys(newState) as Array<keyof T>).forEach((key) => {
    if (key === property) {
      newState[key] = !newState[key] as T[keyof T];
    } else {
      newState[key] = false as T[keyof T];
    }
  });
  return newState as T;
}

/*
Function that takes an object and sets all properties to false
except for the provided property, which will be toggled false if true, true if false.
*/
export function closeAll<T>(drawerState: T): T {
  const newState: Partial<T> = { ...drawerState };
  (Object.keys(newState) as Array<keyof T>).forEach((key) => {
    newState[key] = false as T[keyof T];
  });
  return newState as T;
}
export const detectOs = () => {
  const os = ['Win', 'Mac', 'Linux'];
  return os.find((v) => global.window?.navigator.platform.indexOf(v) >= 0);
};

export const getScale = () => {
  switch (detectOs()) {
    case 'Win':
      return 1;
      break;
    case 'Mac':
      return 1;
      break;
    default:
      return 1;
  }
};

/**
 * Initiates the download of a file from a given URL.
 * @param url - The URL of the file to be downloaded.
 * @param fileName - The name to be given to the downloaded file.
 */
export const downloadFile = async (url: string, fileName: string): Promise<void> => {
  try {
    const response = await fetch(url, {
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch file from ${url}`);
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = fileName;
    anchor.target = '_blank';
    document.body.append(anchor);
    anchor.click();
    anchor.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

/**
 * Checks if the Attachment is an image file.
 * @param file - Attachment to determine if image.
 * @returns boolean or undefined type
 */
export const isImageFile = (file: Attachment) => {
  if (!file) return undefined;
  return (
    (file?.type.includes('image/') ||
      [FileTypes.Jpeg, FileTypes.Png].includes(file.type as FileTypes)) ??
    false
  );
};

export const getRandomItem = (array: number[]) => array[Math.floor(Math.random() * array.length)];

export const upscaleGoogleContentImage = (imageUrl: string, resolution?: number) => {
  // https://lh3.googleusercontent.com/a/XXXXXXXXX=s96-c
  // replace the =s96-c with =s${resolution ?? 800}-c
  // The s parameter specifies the maximum height or width of the image.
  // By default the images are lower resolution, so we need to upscale them
  return imageUrl.replace(/=s\d+-c/, `=s${resolution ?? 800}-c`);
};

/**
 * @param url: string
 * @returns SVG string
 *         Converts the image url to SVG string
 *         Used for displaying the POI icons in 3D viewer
 */
export const urlToSVG = async (url: string): Promise<string> => {
  const response = await fetch(url, {
    credentials: 'include',
  });

  if (!response.ok) {
    return '';
  }
  const svg = await response.text();
  return svg;
};

export function formatNumberForDisplay(value: string | number | null | undefined): string {
  const formattedValue = value && typeof value === 'string' ? Number(value) : value;

  return Number.isNaN(formattedValue) ? 'N/A' : formattedValue?.toLocaleString() ?? '';
}

export const parseNumberOrInfinity = (value: string | undefined) => {
  return Number.isNaN(Number.parseFloat(value || '0'))
    ? Number.POSITIVE_INFINITY
    : Number.parseFloat(value || '0');
};

export const isNumber = (value: unknown): boolean => {
  return typeof value === 'number' && !Number.isNaN(value);
};
