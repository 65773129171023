import { Checkbox, Stack } from '@mui/material';
import { useRecoilState,useSetRecoilState } from 'recoil';
import { useCallback, useMemo } from 'react';
import * as state from '@/components/Analysis/state';
import { ToggleableDefectLegend } from '@/types';

type Props = ToggleableDefectLegend;

export const DefectsToggle = ({ id, name, isEnabled, color, groupName }: Props) => {
   const setBlendingOpacity = useSetRecoilState(state.blendOpacity);
  const [selectedStructureDefectsLegends, setSelectedStructureDefectsLegends] = useRecoilState(
    state.selectedStructureDefectsLegends
  );
  const handleCheckboxChange = useCallback(
    (objectId: number) => {
      if (!selectedStructureDefectsLegends) return;

      const newDefects = selectedStructureDefectsLegends.map((defect) => {
        if (defect.id === objectId) {
          return {
            ...defect,
            isEnabled: !defect.isEnabled,
          };
        }
        if (defect.groupName !== groupName) {
          return {
            ...defect,
            isEnabled: false,
          };
        }
        return defect;
      });
      setSelectedStructureDefectsLegends(newDefects);
      const anySelected = newDefects.some((defect) => defect.isEnabled);
      setBlendingOpacity(anySelected ? 255 : 1);
    },
    [groupName, selectedStructureDefectsLegends, setBlendingOpacity, setSelectedStructureDefectsLegends]
  );

  const colorBoxStyles = useMemo(
    () => ({
      backgroundColor: color,
      height: '2rem',
      width: '2rem',
      borderRadius: '10px',
      border: '1px solid black',
    }),
    [color]
  );

  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" className="defect-toggle-container">
        <Checkbox
          checked={isEnabled}
          onChange={() => handleCheckboxChange(id)}
          className="defect-toggle"
        />
        <div>{name}</div>
      </Stack>
      <div style={colorBoxStyles} />
    </Stack>
  );
};
