import { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { POI_DETAILS_DEFAULT, POI_DETAILS_FALLBACK } from '@/constants';
import { upperSnakeCase } from '@/utils';
import { useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';
import { SvgIcon } from '@mui/material';

export const PoiIcon = ({ name }: { name: string }) => {
  const poiTemplateIcons = useRecoilValue(state.poiTemplateIcons);
  const icon = useMemo(() => {
    const fetchedicon = poiTemplateIcons.find(
      (poiTemplate) => upperSnakeCase(poiTemplate.name) === upperSnakeCase(name)
    )?.icon;
    if (fetchedicon) {
      return (
        <SvgIcon>
          <SVG src={fetchedicon} data-testid={upperSnakeCase(name)} color='white' />
        </SvgIcon>
      );
    }

    return (
      POI_DETAILS_FALLBACK.find(
        (fallbackDetail) => upperSnakeCase(fallbackDetail.name) === upperSnakeCase(name)
      )?.icon ?? POI_DETAILS_DEFAULT.icon
    );
  }, [name, poiTemplateIcons]);
  return icon;
};
