import { useEffect } from 'react';
import { ColorMap } from '@abyss/3d-viewer';
import { useRecoilValue } from 'recoil';
import { NONSELECTED_DEFECT_COLOR, TRANSPARENT_COLOR } from '@/constants';

import * as state from '@/components/Analysis/state';
import { colorWithAlpha, convertColorHexStringToNumber } from '@/utils';
import { OctreeKey } from '@/__generated__/graphql';

export const useDefectColorEffects = (
  defectColorMap: ColorMap | undefined,
  degreeOfRustingColorMap: ColorMap | undefined
) => {
  const structureDefects = useRecoilValue(state.selectedStructureDefectsLegends);
  const nonSelectedDefectsIsEnabled = useRecoilValue(state.nonSelectedDefectsIsEnabled);
  const blendOpacity = useRecoilValue(state.blendOpacity);

  useEffect(() => {
    const nonSelectedColor = nonSelectedDefectsIsEnabled
      ? colorWithAlpha(NONSELECTED_DEFECT_COLOR, blendOpacity)
      : TRANSPARENT_COLOR;
    structureDefects?.forEach(({ isEnabled, color, octreeKey, octreeValue }) => {
      const convertedColor = convertColorHexStringToNumber(color);
      const colorMap =
        octreeKey === OctreeKey.DegreeOfRusting ? degreeOfRustingColorMap : defectColorMap;
      if (colorMap) {
        const unselectedColor = nonSelectedColor;
        colorMap.setColor(octreeValue, isEnabled ? convertedColor : unselectedColor);
      }
    });
  }, [
    defectColorMap,
    degreeOfRustingColorMap,
    structureDefects,
    nonSelectedDefectsIsEnabled,
    blendOpacity,
  ]);
};
