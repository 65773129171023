import { useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { localiseMillimetreMeasurement } from '@/utils/unitSystem';
import { PipeSpec, UnitSystemEnum } from '@/__generated__/graphql';

type Props = {
  isExpanded: boolean;
  onChange: (event: React.SyntheticEvent, expanded: boolean) => void;
  pipeSpec: PipeSpec | null | undefined;
  unitSystem: UnitSystemEnum;
};

/**
 * Pipe spec details presented in an expandable/collapsible accordion
 */
export const PipeSpecAccordion = ({ isExpanded, onChange, pipeSpec, unitSystem }: Props) => {
  const pipeSpecDisplay = useMemo(() => {
    if (!pipeSpec) {
      return {
        title: 'Unknown',
        size: '?',
        nominalCorrosionAllowance: '?',
        nominalThickness: '?',
      };
    }

    const nominalCorrosionAllowance = localiseMillimetreMeasurement(
      unitSystem,
      pipeSpec.nominalCorrosionAllowance
    );
    const nominalThickness = localiseMillimetreMeasurement(unitSystem, pipeSpec.nominalThickness);

    const size = `${pipeSpec.size} ${pipeSpec.schedule}`;
    const title = `${pipeSpec.spec} (${pipeSpec.material})`;

    return { title, size, nominalCorrosionAllowance, nominalThickness };
  }, [pipeSpec, unitSystem]);

  return (
    <Accordion expanded={isExpanded} onChange={onChange} sx={{ mb: 3 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="pipe-spec-content"
        id="pipe-spec-header"
      >
        <Typography>
          <b>Pipe spec </b>
          {pipeSpecDisplay.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2, pt: 0 }}>
        <Typography>Size - {pipeSpecDisplay.size}</Typography>
        <Typography>Nominal thickness - {pipeSpecDisplay.nominalThickness}</Typography>
        <Typography>NCA - {pipeSpecDisplay.nominalCorrosionAllowance}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
