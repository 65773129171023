import { BlendOpacityModes } from '@/types';

/**
 *   ### Get Color Blending Value Mode
 *   #### Description:
 *    This function helps to determine what mode to use for getting/setting the blend
 *    opacity value based on different factors
 */
export const getColorBlendingValueMode = ({
  hasRgb,
  selectedAssemblyId,
  selectedSpherical,
}: {
  selectedAssemblyId: boolean;
  selectedSpherical: boolean;
  hasRgb: boolean;
}): keyof BlendOpacityModes => {
  if (!hasRgb) return 'viewer';
  if (selectedSpherical) return 'spherical';
  if (selectedAssemblyId) return 'assembly';
  return 'viewer';
};
