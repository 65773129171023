import React, { SyntheticEvent, useEffect, useState } from 'react';
import { VisibilityBoxMode, VisibilityBoxProps } from '@abyss/3d-viewer';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NormalVisibilityRange, SphericalPointCloudMode } from '@/types';
import * as state from '@/state';
import { DockPanel } from '@/components/shared/DockPanel';
import { DockPanelItem } from '@/components/shared/DockPanelItem';
import BrushSizeSlider from '@/components/shared/BrushSizeSlider';
import { BlendingModes } from './BlendingModes';
import { AnnotationColorMode } from './AnnotationColorMode';
import { DeSelectAfterSet } from './DeSelectAfterSet';
import { PointSizeControls } from '@/components/shared/PointSizeControls';

export const Visibility = () => {
  const [areSphericalsVisible, setAreSphericalsVisible] = useRecoilState(
    state.areSphericalsVisible
  );

  const { mode: abyssViewerMode } = useRecoilValue(state.abyssViewerState);
  const [normalVisibilityRange, setNormalVisibilityRange] = useRecoilState(
    state.normalVisibilityRange
  );
  const [sphericalPointCloudMode, setSphericalPointCloudMode] = useRecoilState(
    state.sphericalPointCloudMode
  );
  const [currentVisibilityBox, setCurrentVisibilityBox] = useRecoilState(
    state.currentVisibilityBox
  );

  const sphericalPointCloudState = useRecoilValue(state.sphericalPointCloudState);
  const selectedZone = useRecoilValue(state.selectedZone);

  const [customVisibilityBox, setCustomVisibilityBox] = useState<VisibilityBoxProps | undefined>();

  const toggleSphericalVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAreSphericalsVisible(event.target.checked);
  };

  const handleNormalVisibilityRangeChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setNormalVisibilityRange(value as NormalVisibilityRange);
  };

  const handleViewpointVisibilityChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSphericalPointCloudMode(value as SphericalPointCloudMode);
  };

  const toggleEditCustomVisibilityRange = () => {
    if (currentVisibilityBox.mode === VisibilityBoxMode.Adjusting) {
      setCustomVisibilityBox({ ...currentVisibilityBox });
      setCurrentVisibilityBox({
        ...currentVisibilityBox,
        mode: VisibilityBoxMode.Enabled,
      });
    } else {
      setCurrentVisibilityBox({
        ...currentVisibilityBox,
        mode: VisibilityBoxMode.Adjusting,
      });
    }
  };

  useEffect(() => {
    switch (normalVisibilityRange) {
      case 'ZoneOnly':
        if (selectedZone) {
          setCurrentVisibilityBox({
            min: [selectedZone.box.min.x, selectedZone.box.min.y, selectedZone.box.min.z],
            max: [selectedZone.box.max.x, selectedZone.box.max.y, selectedZone.box.max.z],
            mode: VisibilityBoxMode.Enabled,
            step: [0.25, 0.25, 0.1],
          });
        } else {
          setCurrentVisibilityBox({
            ...currentVisibilityBox,
            mode: VisibilityBoxMode.Enabled,
          });
        }
        break;

      case 'All':
        setCurrentVisibilityBox({
          ...currentVisibilityBox,
          mode: VisibilityBoxMode.Disabled,
        });
        break;

      case 'Custom':
        setCurrentVisibilityBox({
          ...(customVisibilityBox || currentVisibilityBox),
          mode: VisibilityBoxMode.Adjusting,
        });

        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone, normalVisibilityRange]);

  const VisibilityRange3D = ({ isDisabled }: { isDisabled?: boolean }) => (
    <DockPanelItem>
      <FormControl>
        <FormLabel>Visibility range</FormLabel>
        <RadioGroup
          value={normalVisibilityRange}
          onChange={handleNormalVisibilityRangeChanged}
          defaultValue="ZoneOnly"
          name="visibility-range-radio-group"
        >
          <FormControlLabel
            value="ZoneOnly"
            control={<Radio />}
            label="Zone only"
            disabled={isDisabled}
          />
          <FormControlLabel value="All" control={<Radio />} label="All" disabled={isDisabled} />
          <div>
            <FormControlLabel
              value="Custom"
              control={<Radio />}
              label="Custom"
              disabled={isDisabled}
            />
            {normalVisibilityRange === 'Custom' && !isDisabled && (
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={toggleEditCustomVisibilityRange}
              >
                {currentVisibilityBox.mode === VisibilityBoxMode.Adjusting
                  ? 'Done Editing'
                  : 'Edit'}
              </Button>
            )}
          </div>
        </RadioGroup>
      </FormControl>
    </DockPanelItem>
  );

  const VisibilityRangeSpherical = () => (
    <DockPanelItem>
      {sphericalPointCloudState === 'Loading' ? (
        <>Loading...</>
      ) : (
        <FormControl>
          <FormLabel>Viewpoint visibility</FormLabel>
          <RadioGroup
            value={sphericalPointCloudMode}
            onChange={handleViewpointVisibilityChanged}
            defaultValue="All"
            name="visibility-range-radio-group"
          >
            <FormControlLabel value="All" control={<Radio />} label="Show all points in range" />
            <FormControlLabel
              value="Related"
              control={<Radio />}
              label="Hide points outside viewpoint"
            />
            <FormControlLabel value="None" control={<Radio />} label="Hide all points" />
          </RadioGroup>
        </FormControl>
      )}
    </DockPanelItem>
  );

  return (
    <DockPanel>
      <DockPanelItem>
        <FormControlLabel
          control={<Checkbox checked={areSphericalsVisible} onChange={toggleSphericalVisibility} />}
          label="Show image viewpoints"
        />
      </DockPanelItem>
      <DockPanelItem>
        <PointSizeControls />
      </DockPanelItem>

      <DockPanelItem>
        <BrushSizeSlider title="Brush size" step={4} min={6} max={32} />
      </DockPanelItem>

      <DockPanelItem>
        <DeSelectAfterSet />
      </DockPanelItem>
      <Divider />

      <BlendingModes />
      <Divider />

      <AnnotationColorMode />
      <Divider />

      {selectedZone ? <VisibilityRange3D isDisabled={abyssViewerMode === 'Spherical'} /> : <></>}
      {abyssViewerMode === 'Spherical' && <VisibilityRangeSpherical />}
    </DockPanel>
  );
};
