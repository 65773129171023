import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  BoxProps,
  Cuboid,
  CuboidEditor,
  CuboidProps,
  TransformTarget,
  VisibilityBoxMode,
} from '@abyss/3d-viewer';
import { AnalysisCuboid } from '@/types';
import * as state from '@/components/Analysis/modules/cuboids/state';
import { makeCuboidPropsFromAnalysisCuboid, useGetAnalysisCuboidColor } from '../hooks';
import * as globalState from '@/components/Analysis/state';
import * as poiState from '../../pointOfInterest/state';
import { useAnalysisCuboidActions } from '../state/actions';

export const SceneCuboids = () => {
  const cuboids = useRecoilValue(state.cuboids);
  const [editingCuboids, setEditingCuboids] = useRecoilState(state.editingCuboids);
  const [currentCuboid, setCurrentCuboid] = useRecoilState(state.currentCuboid);
  const cuboidControlsType = useRecoilValue(state.cuboidControlsType);
  const cuboidTransformMode = useRecoilValue(state.cuboidTransformMode);
  const isCuboidEditorEnabled = useRecoilValue(state.isCuboidEditorEnabled);
  const setCurrentCuboidVisibilityBox = useSetRecoilState(state.currentCuboidVisibilityBox);

  const setSelectedPointOfInterestId = useSetRecoilState(poiState.selectedPointOfInterestId);

  const selectedAssemblyId = useRecoilValue(globalState.selectedAssemblyId);
  const arePoisVisible = useRecoilValue(globalState.arePoisVisible);

  const [transformTarget, setTransformTarget] = useState<TransformTarget>();

  const getCuboidColor = useGetAnalysisCuboidColor();
  const { setEditingCuboidAsCurrent, getSelectedCuboid } = useAnalysisCuboidActions();

  const onBoxUpdate = useCallback(
    (newBox: BoxProps | undefined) => {
      if (newBox) {
        setCurrentCuboidVisibilityBox((current) => ({
          ...current,
          ...newBox,
          mode: VisibilityBoxMode.Enabled,
        }));
      }
    },
    [setCurrentCuboidVisibilityBox]
  );

  const makeCuboidProps = useCallback(
    (cuboid: AnalysisCuboid) => {
      const isSelected = getSelectedCuboid()?.id === cuboid.id;
      const color = getCuboidColor({
        isSelected,
      });
      return makeCuboidPropsFromAnalysisCuboid(cuboid, color);
    },
    [getCuboidColor, getSelectedCuboid]
  );

  const cuboidsFromPois = useMemo(() => {
    // Check to ensure we only render cuboids when an assembly is selected
    if (!selectedAssemblyId) return [];

    // Turning off VOI cuboids when POIs are not visible
    if (!arePoisVisible) return [];

    return cuboids?.map((cuboid) => {
      if (currentCuboid && cuboid.id === currentCuboid.id) return undefined;
      return (
        <Cuboid
          {...makeCuboidProps(cuboid)}
          key={cuboid.id}
          onClick={() => setSelectedPointOfInterestId(cuboid?.poiId)}
          enableClippingByVisibilityBox={false}
        />
      );
    });
  }, [
    cuboids,
    currentCuboid,
    makeCuboidProps,
    selectedAssemblyId,
    setSelectedPointOfInterestId,
    arePoisVisible,
  ]);

  const temporaryCuboids = useMemo(
    () =>
      // Check to ensure we only render cuboids when an assembly is selected
      selectedAssemblyId
        ? editingCuboids?.map((cuboid) => {
            return (
              <Cuboid
                {...makeCuboidProps(cuboid)}
                key={cuboid.id}
                onClick={() => setEditingCuboidAsCurrent(cuboid.id)}
                enableClippingByVisibilityBox={false}
              />
            );
          })
        : [],
    [selectedAssemblyId, editingCuboids, makeCuboidProps, setEditingCuboidAsCurrent]
  );

  const currentCuboidProps = useMemo<CuboidProps | undefined>(() => {
    if (!currentCuboid) return undefined;
    return makeCuboidProps(currentCuboid);
  }, [currentCuboid, makeCuboidProps]);

  const setCurrentCuboidFromViewer: Dispatch<SetStateAction<CuboidProps | undefined>> = useCallback(
    (valueOrFunction: SetStateAction<CuboidProps | undefined>) => {
      setCurrentCuboid((current: AnalysisCuboid | undefined) => {
        if (!current) return undefined;
        const cuboid =
          valueOrFunction instanceof Function
            ? valueOrFunction(makeCuboidProps(current))
            : valueOrFunction;
        if (!cuboid) return undefined;
        const { position, scale, rotation } = cuboid;
        return { ...current, position, scale, rotation };
      });
    },
    [setCurrentCuboid, makeCuboidProps]
  );

  const addEditingCuboidToAllCuboids = (cub: CuboidProps) => {
    if (!currentCuboid) return;

    const { position, rotation, scale } = cub;
    const newCuboid: AnalysisCuboid = {
      ...currentCuboid,
      position,
      rotation,
      scale,
    };

    setEditingCuboids((current) => {
      const newCuboids = current.asMutableStateMap();
      newCuboids?.set(newCuboid.id, newCuboid);
      return newCuboids.asStateMap();
    });
  };

  return (
    <>
      {cuboidsFromPois}
      {temporaryCuboids}
      {currentCuboidProps && (
        <CuboidEditor
          isEnabled={isCuboidEditorEnabled}
          cuboid={currentCuboidProps}
          setCuboid={setCurrentCuboidFromViewer}
          controlsType={cuboidControlsType}
          transformMode={cuboidTransformMode}
          transformTarget={transformTarget}
          setTransformTarget={setTransformTarget}
          onExit={addEditingCuboidToAllCuboids}
          onBoxUpdate={onBoxUpdate}
        />
      )}
    </>
  );
};
