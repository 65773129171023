import React from 'react';

export type AbyssIcons =
  | 'add-image'
  | 'arrow-upward-rounded'
  | 'build'
  | 'calendar'
  | 'check'
  | 'circle-check'
  | 'close'
  | 'collapse'
  | 'comments'
  | 'crown'
  | 'date-picker'
  | 'delete'
  | 'download'
  | 'download-thin'
  | 'expand'
  | 'file-download-line'
  | 'gear'
  | 'image'
  | 'image-filled'
  | 'long-back'
  | 'notification'
  | 'pdf-upload'
  | 'rotate-ccw'
  | 'rotate-cw'
  | 'send'
  | 'screenshot'
  | 'spreadsheet'
  | 'table'
  | 'undo'
  | 'unknown'
  | 'verify-doc'
  | 'versions'
  | 'version-day'
  | 'version-item'
  | 'zoom-in'
  | 'zoom-out';

interface IconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  style?: React.CSSProperties;
  iconName: AbyssIcons;
  pointer?: boolean;
}

export const AbyssIcon: React.FC<IconProps> = ({ style = {}, iconName, pointer, ...rest }) => {
  return (
    <img
      data-testid={`${iconName}-icon`}
      src={`/assets/icons/${iconName}-icon.svg`}
      alt={`${iconName} icon`}
      style={{ height: '2.1rem', ...style, ...(pointer ? { cursor: 'pointer' } : {}) }}
      {...rest}
    />
  );
};
