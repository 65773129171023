import { Box, Divider, Grid, Stack } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { lazy, Suspense, useCallback, useState, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useGeneralFields, useTriggerPoiEditing } from '../../hooks';
import { GeneralFieldsForm } from '../GeneralFieldsForm';
import { TemplateFieldsForm } from '../TemplateFieldsForm';
import { useTemplateFields } from '../../hooks/useTemplateFields';
import {
  useCreatePointOfInterestMutation,
  PoiStatus,
  VisibilityEnum,
  PoiAccessibilityEnum,
  useAllAssembliesForCreatePoiQuery,
  useGetRecommendationsForAssemblyLazyQuery,
  useGetStructureDefaultAssemblyQuery,
  useUpdatePointOfInterestMutation,
  AllPointOfInterestsForDataLoaderDocument,
  PointOfInterestDocument,
  GetAssemblyPointOfInterestsDocument,
  useGetAllTeamsForCreateOrEditPoiQuery,
  GetOneAssemblyForBlistersDocument,
} from '@/__generated__/graphql';
import { convertTemplateStateToTemplateData } from '../../utils';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { PoiFormHeading, PoiFormSubHeading } from '../styles';
import { BlisterFormFields } from '../BlisterFormFields';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import * as state from '@/components/Analysis/state';
import { useBlisterData } from '../../hooks/useBlisterData';
import { useMeasurementTool } from '@/components/Analysis/modules/measurementTool';
import { StyledForm } from '../UpdateGeneralFields/styles';
import { SaveCancelFormButtons } from '../SaveCancelFormButtons';
import { saveCancelButtonCommonStyles } from '../../../styles';
import { convertTemplateNameToPoiType, titleCaseSentence, upperSnakeCase } from '@/utils';
import { enumToString } from '@/utils/string';
import { useViewpointPoiHeight } from '@/components/Analysis/Viewer/hooks/useViewpointPoiHeight';
import { useSavePoiScreenshot } from '@/components/Analysis/modules/pointOfInterest/createOrEdit/useSavePoiScreenshot';
import { paintDiameterSharedState } from '@/components/shared/CalculatedFields/state';
import { BLISTER, BLISTER_TYPE, PIT, PIT_TYPE } from '@/constants';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import { humanizeString } from '@/utils/string';
import * as cuboidsState from '../../../../../cuboids/state';
import { LinkedPoiEditor } from '../LinkedPoiEditor';
import { useAnalysisCuboidActions } from '@/components/Analysis/modules/cuboids/state/actions';
import { usePoiFlow } from '@/components/Analysis/Insights/InsightsExplorer/PointOfInterests/usePoiFlow';
import { DropDownField } from '@/components/shared/CustomField';
import { PointOfInterestTemplateDocument } from '@/types';
import { InitialiseConfirmationDialog } from '../InitialiseConfirmationDialog';
import { isPoiMeasurementToolCompatible, propsToPoiLineMeasurement } from '@/components/Analysis/modules/measurementTool/hooks/utils';

const fieldsContainer = {
  '& > *': { my: '4%' },
};

const BlisterMeasurementLazy = lazy(() =>
  import('../../../../createOrEdit').then(({ BlisterMeasurement }) => ({
    default: BlisterMeasurement,
  }))
);

export const CreatePoi = () => {
  const [editPointOfInterest, setEditPointOfInterest] = useRecoilState(
    poiState.editPointOfInterest
  );
  const allPoiTemplates = useRecoilValue(state.allPointOfInterestTemplates);
  const allVoiTemplates = useRecoilValue(state.allVoiTemplates);
  const setPaintDiameter = useSetRecoilState(paintDiameterSharedState);
  const heightToPointOfInterest = useViewpointPoiHeight();
  const { handleSavePoiScreenshot } = useSavePoiScreenshot();
  const setAllPOI = useSetRecoilState(poiState.structurePois);
  const editingCuboids = useRecoilValue(cuboidsState.editingCuboids);
  const cuboidLinkedPois = useRecoilValue(cuboidsState.cuboidLinkedPois);
  const isCuboidEditorEnabled = useRecoilValue(cuboidsState.isCuboidEditorEnabled);
  const setNominatedBlister = useSetRecoilState(state.governingValueByAssemblyId);
  const selectedAssembly = useRecoilValue(state.selectedAssemblyId);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const setAreSphericalsVisible = useSetRecoilState(state.areSphericalsVisible);
  const setEnableViewpoints = useSetRecoilState(state.enableViewpoints);
  const setShowPointCloudInSpherical = useSetRecoilState(state.showPointCloudInSpherical);
  const setArePoisVisible = useSetRecoilState(state.arePoisVisible);

  const setManualBlisterHeightExists = useSetRecoilState(poiState.manualBlisterHeightExists);

  const navbarHeight = useNavbarHeight();

  const { measurements } = useMeasurementTool();

  const { currentTemplate, selectedAssemblyId, setAssemblyId, changePoiTemplate } = usePoiFlow();
  const pointOfInterest = editPointOfInterest?.pointOfInterest;
  const template = editPointOfInterest?.template;
  const isManuallyCreated = editPointOfInterest?.isManuallyCreated;
  const isAutoGenerated = editPointOfInterest?.isAutoGenerated;

  const { cancelEditing } = useTriggerPoiEditing();
  const { shiftAllEditingCuboidsToCuboids, stopAddingCuboid } = useAnalysisCuboidActions();

  const onClose = useCallback(() => {
    setPaintDiameter('0');
    cancelEditing();
    stopAddingCuboid();
  }, [cancelEditing, setPaintDiameter, stopAddingCuboid]);

  const poiType = convertTemplateNameToPoiType(template?.name);

  const [getAssemblyRecommendation] = useGetRecommendationsForAssemblyLazyQuery({
    variables: {
      assemblyId: selectedAssemblyId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: teamsData, loading: teamsLoading } = useGetAllTeamsForCreateOrEditPoiQuery();

  const teamOptions = useMemo(() => {
    return teamsData?.allTeams?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [teamsData?.allTeams]);
  const setSelectedPointOfInterestId = useSetRecoilState(poiState.selectedPointOfInterestId);
  const allTemplates = useRecoilValue(state.allPointOfInterestTemplates);
  const [loading, setLoading] = useState(false);

  const {
    localBlisterData,
    blisterFieldsData,
    pointSelectMessage,
    onMeasure,
    onSelectPoint,
    clearBlisterData,
    handleManualHeightChange,
    handleMeasureIconClick,
    handleChangeScCategory,
  } = useBlisterData(poiType);

  const currentSpherical = useRecoilValue(state.viewerCurrentSpherical);
  const assemblyId = pointOfInterest?.assembly?.id;
  const structureId = pointOfInterest?.assembly?.structure?.id ?? '';

  const { data: assembliesData } = useAllAssembliesForCreatePoiQuery({
    variables: { structureId },
  });

  const { data: structureWithDefaultAssembly } = useGetStructureDefaultAssemblyQuery({
    variables: { structureId },
  });

  const generalFieldsData = useGeneralFields({
    assignee: 'None',
    dueDate: undefined,
    status: PoiStatus.Inreview,
    visibility: VisibilityEnum.Public,
    name: poiType ? humanizeString(poiType.toString(), '_') : '',
    teamIds: [],
    workpackNumber: 'N/A',
    comment: '',
    accessibility: undefined,
  });

  const templateFieldsData = useTemplateFields({
    selectedPoiInfo: pointOfInterest,
    templateFields: template?.fields ?? [],
  });

  const { showSnackBar } = useSnackBarMessage({ variant: 'filled' });

  const [handlePoiCreate] = useCreatePointOfInterestMutation({
    refetchQueries: [GetAssemblyPointOfInterestsDocument, GetOneAssemblyForBlistersDocument],
  });
  const [updatePointOfInterestMutation] = useUpdatePointOfInterestMutation();

  const { inputAdormantHandlerMap, cancelMeasuring } = useMeasurementTool();

  const showBlisterFields = [PIT_TYPE, BLISTER_TYPE].includes(poiType || '');

  const isPoiTypeOfVoi = allVoiTemplates.includes(enumToString(poiType) || '');

  const onPitDropdownFieldChange = useCallback(
    (manualTemplateValue: boolean) => {
      // If the user decides to mark the Blister as PIT
      // update the name of the POI to reflect the change
      generalFieldsData.setState((current) => ({
        ...current,
        // manualTemplateValue being true means the user has checked the checkbox
        // meaning they want to mark the Blister as PIT
        name: manualTemplateValue ? PIT : BLISTER,
      }));
      const newTemplate = allTemplates.find(
        (item) => item.name === (manualTemplateValue ? PIT : BLISTER)
      ) as PointOfInterestTemplateDocument | undefined;

      if (newTemplate) {
        setEditPointOfInterest((current) => ({
          ...current,
          template: newTemplate,
        }));
      }
    },
    [allTemplates, generalFieldsData, setEditPointOfInterest]
  );

  const handlePointOfInterest = useCallback(
    async (isInitialization: boolean) => {
      const templateData = convertTemplateStateToTemplateData(templateFieldsData.state);
      const generalFields = generalFieldsData.state;
      const type = isInitialization ? BLISTER_TYPE : template?.name;
      const centerPoint3d = pointOfInterest?.centerPoint3d;
      const cameraLocation = pointOfInterest?.cameraLocation;

      const locationId = currentSpherical?.id;
      const structureDefaultAssembly = structureWithDefaultAssembly?.structure?.defaultAssemblyId;

      if (showBlisterFields && !editPointOfInterest.hasBlisterMeasurement) {
        showSnackBar('Please Generate Measurement before save.', 'error');
        return;
      }

      if (
        !structureId ||
        (!isManuallyCreated && !assemblyId) ||
        !type ||
        !templateData ||
        !generalFields ||
        !poiType
      ) {
        showSnackBar(
          `Error ${isInitialization ? 'saving' : 'creating'} Point Of Interest. Please contact support if the issue persists.`,
          'error'
        );
        return;
      }

      if (isManuallyCreated && !assemblyId && !structureDefaultAssembly) {
        showSnackBar(
          `Error  ${isInitialization ? 'saving' : 'creating'} Point Of Interest. Uncontextualized Assembly not found. Please contact support if the issue persists.`,
          'error'
        );
        return;
      }

      const accessibilityEnum = Object.values(PoiAccessibilityEnum).find(
        (accessibilityValue) =>
          accessibilityValue === upperSnakeCase(generalFieldsData.state.accessibility ?? '')
      );

      const commonInputFields = {
        ...generalFields,
        name: generalFields?.name ?? (isInitialization ? titleCaseSentence(type) : ''),
        centerPoint3d,
        cameraLocation,
        structureId,
        assemblyId: assemblyId || structureDefaultAssembly || '',
        type: poiType,
        templateData,
        templateId: template?.id,
        locationId,
        blister: localBlisterData,
        dueDate: generalFields?.dueDate?.toISOString(),
        workpackNumber: generalFields.workpackNumber,
        ...(accessibilityEnum ? { accessibility: accessibilityEnum } : {}),
        ...(heightToPointOfInterest ? { viewpointRelativeHeight: heightToPointOfInterest } : {}),
        cuboids: isPoiTypeOfVoi
          ? editingCuboids.map((cuboid) => ({
              position: cuboid.position,
              rotation: cuboid.rotation,
              scale: cuboid.scale,
            }))
          : undefined,
        linkedPointOfInterestIds: isPoiTypeOfVoi
          ? cuboidLinkedPois?.filter((item) => item?.checked)?.map(({ id }) => id)
          : undefined,
      };

      setLoading(true);
      let pointOfInterestId: string | undefined;

      try {
        if (isInitialization) {
          await updatePointOfInterestMutation({
            variables: {
              input: {
                pointOfInterestId: editPointOfInterest.pointOfInterest?.id || '',
                ...commonInputFields,
                isUninitialized: true,
                lastModifiedFor: 'Initialize Auto POI',
              },
            },
            refetchQueries: [AllPointOfInterestsForDataLoaderDocument, PointOfInterestDocument],
          });
          pointOfInterestId = editPointOfInterest.pointOfInterest?.id;
        } else {
          const response = await handlePoiCreate({
            variables: {
              input: {
                ...commonInputFields,
                lastModifiedFor: 'Initialization',
                lineMeasurement: isPoiMeasurementToolCompatible(poiType)
                  ? propsToPoiLineMeasurement(measurements)
                  : undefined,
              },
            },
          });

          const newPoi = response.data?.createPointOfInterest;
          if (newPoi && !isManuallyCreated) setAllPOI((previous) => [...previous, newPoi]);
          pointOfInterestId = newPoi?.id;
        }
        cancelMeasuring();

        setSelectedPointOfInterestId(pointOfInterestId);
        setPaintDiameter('0');
        setManualBlisterHeightExists(false);
        showSnackBar(
          isAutoGenerated
            ? 'The POI has been saved successfully'
            : 'Point of interest created successfully',
          'success'
        );
        shiftAllEditingCuboidsToCuboids();

        // only hide point cloud and pois in spherical
        if (currentSpherical) {
          setShowPointCloudInSpherical(false);
          setArePoisVisible(false);
        }

        setAreSphericalsVisible(false);
        setEnableViewpoints(undefined);
        onClose();

        // add a delay on the screenshot to ensure the pois and viewpoints are hidden
        setTimeout(() => {
          handleSavePoiScreenshot({
            poiId: pointOfInterestId,
          });
        }, 500);

        if (selectedAssembly) {
          await getAssemblyRecommendation({
            variables: {
              assemblyId: selectedAssembly,
            },
          }).then((assemblyRecommendation) => {
            const governingPOIId =
              assemblyRecommendation.data?.assembly?.recommendation?.governingValue?.pointOfInterest
                ?.id;
            if (governingPOIId) {
              setNominatedBlister((current) => ({
                ...current,
                [selectedAssembly]: {
                  pointOfInterest: {
                    id: governingPOIId,
                  },
                },
              }));
            }
          });
        }
      } catch {
        showSnackBar(
          `Could not ${isAutoGenerated ? 'save' : 'create'} point of interest. Please try again`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [
      measurements,
      templateFieldsData.state,
      generalFieldsData.state,
      template?.name,
      template?.id,
      pointOfInterest?.centerPoint3d,
      pointOfInterest?.cameraLocation,
      currentSpherical,
      structureWithDefaultAssembly?.structure?.defaultAssemblyId,
      showBlisterFields,
      editPointOfInterest.hasBlisterMeasurement,
      editPointOfInterest.pointOfInterest?.id,
      structureId,
      isManuallyCreated,
      assemblyId,
      poiType,
      localBlisterData,
      heightToPointOfInterest,
      isPoiTypeOfVoi,
      editingCuboids,
      cuboidLinkedPois,
      showSnackBar,
      cancelMeasuring,
      selectedAssembly,
      getAssemblyRecommendation,
      setSelectedPointOfInterestId,
      setPaintDiameter,
      setManualBlisterHeightExists,
      isAutoGenerated,
      shiftAllEditingCuboidsToCuboids,
      setShowPointCloudInSpherical,
      setAreSphericalsVisible,
      setEnableViewpoints,
      setArePoisVisible,
      onClose,
      handleSavePoiScreenshot,
      updatePointOfInterestMutation,
      handlePoiCreate,
      setAllPOI,
      setNominatedBlister,
    ]
  );

  const poiTypeOptions = useMemo(() => {
    const excludedPoiTypes = new Set(['Blister', 'Paint Damage', 'Pit', 'Recommendation']);
    return allPoiTemplates
      .filter(({ name }) => !excludedPoiTypes.has(name))
      .map(({ name }) => name);
  }, [allPoiTemplates]);

  const assemblies = useMemo(
    () => assembliesData?.allAssemblies ?? [],
    [assembliesData?.allAssemblies]
  );

  const handleAssemblyChange = useCallback(
    (assemblyName: string) => {
      const assembly = assemblies.find(({ tagName }) => tagName === assemblyName);
      setAssemblyId(assembly?.id ?? '');
    },
    [assemblies, setAssemblyId]
  );

  return (
    <Grid container sx={{ height: '100%' }}>
      <InitialiseConfirmationDialog
        open={showConfirmationModal}
        sphericalChanged={currentSpherical?.id !== editPointOfInterest.poiSphericalId}
        handleClose={() => {
          setShowConfirmationModal(false);
        }}
        handleConfirm={async () => {
          setShowConfirmationModal(false);
          await handlePointOfInterest(!!isAutoGenerated);
        }}
      />
      {localBlisterData && (
        <Grid item xs={7}>
          <Suspense>
            <BlisterMeasurementLazy
              poiToAdd={pointOfInterest}
              onMeasure={onMeasure}
              pointSelectMessage={pointSelectMessage}
              onSelectPoint={onSelectPoint}
              clearBlisterData={clearBlisterData}
              measureOnLoad={editPointOfInterest.formState === 'Create'}
            />
          </Suspense>
        </Grid>
      )}
      <Grid item xs={localBlisterData ? 5 : 12}>
        <StyledForm
          onSubmit={async (event) => {
            event.preventDefault();
            if (isAutoGenerated) {
              setShowConfirmationModal(true);
            } else {
              await handlePointOfInterest(!!isAutoGenerated);
            }
          }}
          style={{ borderLeft: '1px solid #ddd' }}
        >
          <Stack
            sx={{ height: `calc(100vh / var(--zoom) - ${navbarHeight}px)`, overflowY: 'auto' }}
          >
            <Box flex={1} mb="8rem">
              <Stack direction="column" sx={{ px: 2 }}>
                <PoiFormHeading variant="h5">Summary</PoiFormHeading>

                <Box sx={fieldsContainer}>
                  <PoiFormSubHeading variant="h5">POI specifications</PoiFormSubHeading>
                  {editPointOfInterest.isManuallyCreated && (
                    <>
                      <DropDownField
                        id="poi-type"
                        value={currentTemplate}
                        mode="Edit"
                        title="POI Type"
                        isClearAble={false}
                        options={poiTypeOptions}
                        onFieldValueChange={changePoiTemplate}
                      />
                      <DropDownField
                        id="assemblies"
                        value={selectedAssemblyId}
                        mode="Edit"
                        title="Select Assembly"
                        optionsLimit={assemblies.length}
                        options={assemblies.map(({ tagName }) => tagName)}
                        onFieldValueChange={handleAssemblyChange}
                      />
                    </>
                  )}
                  {showBlisterFields && (
                    <BlisterFormFields
                      blisterData={blisterFieldsData}
                      handleManualHeightChange={handleManualHeightChange}
                      handleMeasureIconClick={handleMeasureIconClick}
                      handleChangeScCategory={handleChangeScCategory}
                      onFieldChange={onPitDropdownFieldChange}
                    />
                  )}
                  <TemplateFieldsForm
                    template={template}
                    dataHook={templateFieldsData}
                    formState={editPointOfInterest?.formState}
                    inputAdormantHandlerMap={inputAdormantHandlerMap}
                  />
                </Box>
              </Stack>

              <Divider sx={{ mt: 4, color: '#B8CADD' }} />

              <Stack direction="column" sx={{ px: 2 }}>
                <Box sx={fieldsContainer}>
                  <PoiFormSubHeading variant="h5" mt={2}>
                    General Information
                  </PoiFormSubHeading>
                  {isPoiTypeOfVoi && <LinkedPoiEditor />}
                  <GeneralFieldsForm
                    teamOptions={teamOptions}
                    teamsLoading={teamsLoading}
                    dataHook={generalFieldsData}
                  />
                </Box>
              </Stack>
            </Box>

            <SaveCancelFormButtons
              cancelButtonLabel="Cancel"
              buttonLabel={isAutoGenerated ? 'Save POI' : 'Add POI'}
              loadingLabel={isAutoGenerated ? 'Saving...' : 'Creating...'}
              onCancel={() => {
                cancelMeasuring();
                onClose();
                setManualBlisterHeightExists(false);
              }}
              loading={loading}
              buttonIcon={<AddCircleIcon />}
              sx={saveCancelButtonCommonStyles.sx}
              saveButtonStyles={saveCancelButtonCommonStyles.saveButtonStyles}
              cancelButtonStyles={saveCancelButtonCommonStyles.cancelButtonStyles}
              saveButtonDisabled={isCuboidEditorEnabled}
            />
          </Stack>
        </StyledForm>
      </Grid>
    </Grid>
  );
};
