import { Theme } from '@mui/material';

export const MuiButton = (baseTheme: Theme) => {
  const common = {
    contained: {
      '&:hover': {
        backgroundColor: baseTheme.palette.primary.light,
        boxShadow: 'none',
      },
      '&:active': {
        backgroundColor: baseTheme.palette.primary.dark,
        boxShadow: 'none',
      },
      '&:focus': {
        backgroundColor: baseTheme.palette.primary.dark,
        boxShadow: 'none',
      },
    },
    outlined: {
      border: `1px solid ${baseTheme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: baseTheme.palette.secondary.light,
        boxShadow: 'none',
      },

      '&:focus': {},
      '&:disabled': {},
    },
    text: {
      '&:hover': {
        backgroundColor: baseTheme.palette.secondary.light,
      },
      '&:focus': {},
      '&:disabled': {},
    },
    small: {
      padding: '0.4rem 0.8rem',
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '1.8rem',
    },
    medium: {
      padding: '0.8rem 1.2rem',
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '1.8rem',
    },
    large: {
      padding: '1.2rem 1.6rem',
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '2rem',
    },
  };

  return {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        boxShadow: 'none',
      },
    },
    variants: [
      {
        props: { size: 'small', variant: 'text' },
        style: {
          ...common.text,
          ...common.small,
        },
      },
      {
        props: { size: 'small', variant: 'outlined' },
        style: {
          ...common.outlined,
          ...common.small,
        },
      },
      {
        props: { size: 'small', variant: 'contained' },
        style: {
          ...common.contained,
          ...common.small,
        },
      },
      {
        props: { size: 'medium', variant: 'text' },
        style: {
          ...common.text,
          ...common.medium,
        },
      },
      {
        props: { size: 'medium', variant: 'outlined' },
        style: {
          ...common.outlined,
          ...common.medium,
        },
      },
      {
        props: { size: 'medium', variant: 'contained' },
        style: {
          ...common.contained,
          ...common.medium,
        },
      },
      {
        props: { size: 'large', variant: 'text' },
        style: {
          ...common.text,
          ...common.large,
        },
      },
      {
        props: { size: 'large', variant: 'outlined' },
        style: {
          ...common.outlined,
          ...common.large,
        },
      },
      {
        props: { size: 'large', variant: 'contained' },
        style: {
          ...common.contained,
          ...common.large,
        },
      },
    ],
  };
};
