import { abyssColors } from '@/theme/colors';
import { Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { typographyStyles } from './styles';

type Props = {
  title: string;
  my?: number;
  insideAccordion?: boolean;
  subtitle?: string;
  // This type below means that, there 'value' and 'children' are exclusive, meaning that only one of them can be passed at a time.
  // This is done to add the ability to pass custom JSX elements as children to the Detail component.
} & ({ value: string | number; children?: never } | { value?: never; children: React.ReactNode });

const primaryColors = abyssColors.primary;

export const Detail = ({
  title,
  value,
  my = 1.5,
  insideAccordion = true,
  children,
  subtitle,
}: Props) => {
  const { titleStyles, valueStyles } = useMemo(
    () => ({
      titleStyles: {
        ...typographyStyles,
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: insideAccordion ? 400 : 500,
        color: primaryColors[insideAccordion ? 300 : 400],
      },
      valueStyles: {
        ...typographyStyles,
        minWidth: 80,
        textAlign: 'right',
        fontSize: '14px',
        color: primaryColors[500],
        fontWeight: insideAccordion ? 400 : 500,
      },
    }),
    [insideAccordion]
  );

  return (
    <Stack spacing={1} my={my} direction="row" alignItems="center" justifyContent="space-between">
      <Tooltip placement="top" title={title.length > 22 ? title : ''} arrow>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography sx={titleStyles}>{title}</Typography>
          {subtitle ? (
            <Typography fontSize={'10px'} color="text.secondary">
              {subtitle}
            </Typography>
          ) : (
            <></>
          )}
        </Stack>
      </Tooltip>
      {children || (
        <Tooltip placement="top" title={(value || '').toString().length > 15 ? value : ''} arrow>
          <Typography sx={valueStyles}>{value}</Typography>
        </Tooltip>
      )}
    </Stack>
  );
};
