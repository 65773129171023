import React, { useCallback, useMemo, KeyboardEvent } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  AbyssViewerContextProvider,
  ColorMap,
  PointCloudMaterialProps,
  PointPickResult,
  SegmentProps,
  SphericalImageProps,
} from '@abyss/3d-viewer';
import { Alert } from '@mui/material';
import { AbyssViewer } from './AbyssViewer';
import { Container } from './styles';
import * as state from '@/state';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { TestIds } from '../../../../../../cypress/testIds';
import { DEFAULT_LABELLING_COLORS } from '@/constants';
import { useToggleSuperVoxelSelection } from './ViewerModes/handlers';
import { useAnnotationModeEffect } from './ViewerModes/AnnotationMode';
import { useBlendingModeEffect } from './ViewerModes/BlendingMode';
import { usePointSize } from '@/hooks';

const canvasProps = {
  style: {
    background: '#263238',
    height: '100%',
  },
  gl: { antialias: true, preserveDrawingBuffer: true },
};

export const Inspection3dViewer = () => {
  const token = useRecoilValue(state.auth0TokenState);
  const recoilInspectionMetadata = useRecoilValue(state.inspectionMetadata);
  const recoilStructureLocations = useRecoilValue(state.structureLocations);
  const structureId = useRecoilValue(state.selectedStructureId);
  const segmentsByCurrentZones = useRecoilValue(state.getSegmentsByCurrentZones);
  const selectedZone = useRecoilValue(state.selectedZone);
  const inSphericalView = useRecoilValue(state.inSphericalView);
  const setSphericalPointCloudMode = useSetRecoilState(state.sphericalPointCloudMode);
  const normalVisibilityRange = useRecoilValue(state.normalVisibilityRange);
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const labellingStructureRelationships = useRecoilValue(state.labellingStructureRelationships);

  const { pointSize, enableSizeAttenuation } = usePointSize();

  // Workaround for slow loading of bin and image files in Pakistan
  // 1. Get the point cloud and image files
  //     - Download 6424ac6b89e061acf27acd81-2M from https://drive.google.com/file/d/1uyd32ds5cW8Dy7quJr4CzfzFmaMzUsso/view?usp=sharing
  //     - Extract to a folder e.g. C:/ - you should see one folder inside "6424ac6b89e061acf27acd81-2M" - "6424ac6b89e061acf27acd81" with octree files and spherical images.
  //     - Rename the containing folder to 6424ac6b89e061acf27acd81
  // 2. Host these files on a server within your own machine
  //     - Download and install LTS version of npm releavant to your OS https://nodejs.org/en/download/
  //     - From a command line run: npm install --global http-server
  //     - From a command line, navigate to the folder 6424ac6b89e061acf27acd81
  //     - From within 6424ac6b89e061acf27acd81 in command line run: http-server --cors='*'
  //     - You should see some output saying Available on: http://127.0.0.1:8080
  // 3. Enable Fabric V2 to load files from your own machine
  //     - Go to Fabric V2 temporary version here, stay on Platforms list page for now.
  //     - Open Chrome dev tools (usually Ctrl+Shift+i)
  //     - Open Console tab (usually second from the left after Elements)
  //     - Run the following in Console tab (paste this in then hit Enter): localStorage.setItem("files-base-url", "http://localhost:8080")
  //     - Select Label Test Deployment 2M platform - this should now load point cloud and image files from your local machine

  // To use a http server instead of local machine
  // 1. Set up file/folder structure on http server as follows
  // /6424ac6b89e061acf27acd81 - Label Test Deployment 2M - lower priority, once 500K is working end to end
  //    /spherical_images.json - should contain meta-data for all the .jpg files for Label Test Deployment 2M sphericals
  //    /octree - should contain octree_hierarch.json and .bin files for Label Test Deployment 2M
  // /64255bbffd448c68393dd87c - Label Test Deployment 500K -
  //    /spherical_images.json - should contain meta-data for all the .jpg files for marlin UD sphericals
  //    /octree - should contain octree_hierarch.json and .bin files for marlin UD

  // 2. Enable Fabric V2 to load files from your own machine - this only works for Horn Mountain PD
  //     - Go to Fabric V2 temporary version here and log in, stay on Platforms list page for now - https://d2gplkryt7cldz.cloudfront.net/
  //     - Open Chrome dev tools (usually Ctrl+Shift+i)
  //     - Open Console tab (usually second from the left after Elements)
  //     - Run the following in Console tab and replace http://localhost:8080 with your http server url (paste this in then hit Enter): localStorage.setItem("files-base-url", "http://localhost:8080")
  //     - Select any platform - this should now load point cloud and image files from your local machine

  const octreePointCloudsJsonPath = useMemo(() => {
    const filesBaseUrl = localStorage.getItem('files-base-url');
    return [
      {
        path: filesBaseUrl
          ? `${filesBaseUrl}/${structureId}/octree/octree_hierarchy.json`
          : getCloudfrontUrl(recoilInspectionMetadata?.octreeResourcesPath as string),
        contents: [{ superVoxel: true }],
      },
    ];
  }, [structureId, recoilInspectionMetadata]);

  const sphericalImages = useMemo(() => {
    const filesBaseUrl = localStorage.getItem('files-base-url');
    return recoilStructureLocations
      ?.filter((location) => location.resourcePath)
      .map<SphericalImageProps>((location) => {
        return {
          name: location.name,
          url: filesBaseUrl
            ? `${filesBaseUrl}/${structureId}/images/${location.name}.jpg`
            : getCloudfrontUrl(location.resourcePath!),
          position: [location.pose?.x, location.pose?.y, location.pose?.z],
          rotation: [location.pose?.roll, location.pose?.pitch, location.pose?.yaw],
          imageYawOffset: location.pose?.yawOffset,
        } as SphericalImageProps;
      });
  }, [recoilStructureLocations, structureId]);

  // Performant colorMap, we create once then re-use it everytime point cloud material changes
  const colorMap: ColorMap | undefined = useMemo(() => {
    const maxId = 1024 * 1024 - 1; // should be same as `maxId` of `superVoxel` field in octree dataformat
    return new ColorMap(maxId, undefined, 0x0);
  }, []);

  const pointCloudMaterials = useMemo(() => {
    return new Map<string, PointCloudMaterialProps>([
      [
        'superVoxel',
        {
          colorMap,
          attributeName: 'superVoxel',
          pointSize,
          enableSizeAttenuation,
          hasColor: true,
        },
      ],
    ]);
  }, [pointSize, enableSizeAttenuation, colorMap]);

  const { toggleMultiSuperVoxelSelection, toggleSuperVoxelSelection } =
    useToggleSuperVoxelSelection();

  useAnnotationModeEffect(colorMap, DEFAULT_LABELLING_COLORS);
  useBlendingModeEffect(colorMap, DEFAULT_LABELLING_COLORS);

  const handlePointSelect = useCallback(
    (pickResult: PointPickResult) => {
      const attributeNames = ['position', 'superVoxel'];

      const annotation3dReference = pickResult
        .getPointAttributes(pickResult.pointIndex, attributeNames)
        .get('superVoxel');

      if (annotation3dReference !== undefined && !Array.isArray(annotation3dReference)) {
        toggleSuperVoxelSelection(
          annotation3dReference,
          pickResult.mouseEvent.shiftKey,
          pickResult.mouseEvent.altKey
        );
      }
    },
    [toggleSuperVoxelSelection]
  );

  const handleBoxSelect = useCallback(
    (pointPickResults: PointPickResult[]) => {
      if (pointPickResults.length === 0) {
        setSnackbarMessage({
          shouldShow: true,
          content: <Alert severity="warning">Point Pick Result is Empty</Alert>,
        });
        return;
      }
      if (
        (!selectedZone && inSphericalView) ||
        (selectedZone && inSphericalView && normalVisibilityRange === 'All')
      ) {
        setSnackbarMessage({
          shouldShow: true,
          content: (
            <Alert severity="warning">
              Please select a zone and pick zone only or custom visibility range before using
              multi-select in viewpoint mode
            </Alert>
          ),
        });
        return;
      }
      const annotationIds = new Set<number>();

      let isShiftKeyDown = false;

      pointPickResults.forEach((pointPickResult) => {
        const { pointIndex, getPointAttributes, mouseEvent } = pointPickResult;
        const attributeNames = ['position', 'superVoxel'];
        const annotation3dReference = getPointAttributes(pointIndex, attributeNames).get(
          'superVoxel'
        );
        if (mouseEvent.shiftKey) {
          isShiftKeyDown = true;
        }

        if (annotation3dReference !== undefined && !Array.isArray(annotation3dReference)) {
          // Here we are checking if a particular annotation3dReference does exist in state.
          // Due to data inconsitencies, this check is needed but can be removed in future
          const isAnotationReferenceExistInState =
            labellingStructureRelationships?.byAnnotation3dReference[annotation3dReference];

          if (!isAnotationReferenceExistInState) return;

          annotationIds.add(annotation3dReference);
        }
      });
      toggleMultiSuperVoxelSelection([...annotationIds], isShiftKeyDown);
    },
    [
      selectedZone,
      setSnackbarMessage,
      toggleMultiSuperVoxelSelection,
      labellingStructureRelationships,
      inSphericalView,
      normalVisibilityRange,
    ]
  );

  const selectionColors = useMemo(() => {
    return {
      shiftKeyColor: DEFAULT_LABELLING_COLORS.highlightColor,
      altKeyColor: DEFAULT_LABELLING_COLORS.deselectColor,
    };
  }, []);

  const segments: SegmentProps[] = useMemo(() => {
    return segmentsByCurrentZones;
  }, [segmentsByCurrentZones]);

  /**
   * The keypress event is depreceated, so we are using keydown event. The Keypress is sent to an element when the browser registers keyboard input.
   * This is similar to the keydown event, except that modifier and non-printing keys such as Shift, Esc, and delete trigger keydown events but not keypress events.
   * The keyup event is sent to an element when the user releases a key on the keyboard.
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event
   */
  const toggleVisiblilityRangeSpherical = useCallback(
    (event: KeyboardEvent) => {
      if (inSphericalView && event.key === 'l') {
        setSphericalPointCloudMode((lastValue) => {
          if (lastValue === 'None') {
            return 'All';
          }
          return 'None';
        });
      }
    },
    [inSphericalView, setSphericalPointCloudMode]
  );

  return (
    <>
      {recoilInspectionMetadata && sphericalImages && token && (
        <Container data-test-id={TestIds.LABELLING_TABLE_CONTAINER}>
          <div
            style={{ width: '100%', height: '100%' }}
            tabIndex={1}
            onKeyDown={toggleVisiblilityRangeSpherical}
          >
            <AbyssViewerContextProvider canvasProps={canvasProps}>
              <AbyssViewer
                octreePointCloudsJsonPath={octreePointCloudsJsonPath}
                onPointSelect={handlePointSelect}
                onPointCloudSelection={handleBoxSelect}
                pointCloudMaterials={pointCloudMaterials}
                sphericalImages={sphericalImages}
                selectionColors={selectionColors}
                segments={segments}
              />
            </AbyssViewerContextProvider>
          </div>
        </Container>
      )}
    </>
  );
};
