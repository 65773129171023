import { DefaultValue, selector } from 'recoil';
import { CurrentImageProps, ImageType } from '@abyss/3d-viewer';
import * as state from '../atoms';
import { getCloudfrontUrl } from '@/utils/cloudfront';
import { getColorBlendingValueMode } from '@/utils/misc';

export const selectedSpherical = selector<CurrentImageProps | undefined>({
  get: ({ get }) => get(state.viewerCurrentSpherical),
  set: ({ set, get }, newValue: CurrentImageProps | undefined | DefaultValue) => {
    if (newValue !== undefined) {
      const locationsMap = get(state.structureLocationsMap);

      const imageLayer = get(state.imageLayer);
      if ('imageType' in newValue && locationsMap && newValue.imageType === ImageType.Spherical) {
        const location = locationsMap.get(newValue.id ?? '');
        if (location && imageLayer) {
          newValue.url = getCloudfrontUrl(
            `${location.baseResourcePath}/${imageLayer.type}/${location.scanKey}.${imageLayer.fileExt}`
          );
        } else {
          newValue.url = getCloudfrontUrl(location?.resourcePath ?? '');
        }
      }
    }

    return set(state.viewerNextCurrentSpherical, newValue);
  },
  key: 'AnalysisSelectedSpherical',
});

export const blendOpacity = selector<number>({
  get: ({ get }) => {
    const value = get(state.blendOpacityCombined);

    const mode = getColorBlendingValueMode({
      hasRgb: get(state.hasRgb),
      selectedAssemblyId: !!get(state.selectedAssemblyId),
      selectedSpherical: !!get(selectedSpherical),
    });

    return value[mode];
  },
  set: ({ set, get }, newValue) => {
    if (typeof newValue !== 'number') return;

    const value = get(state.blendOpacityCombined);

    const mode = getColorBlendingValueMode({
      hasRgb: get(state.hasRgb),
      selectedAssemblyId: !!get(state.selectedAssemblyId),
      selectedSpherical: !!get(selectedSpherical),
    });

    set(state.blendOpacityCombined, {
      ...value,
      [mode]: newValue,
    });
  },
  key: 'blendOpacity',
});
