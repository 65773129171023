import { MeasurementLineStyle, PoiLineMeasurement } from '@/__generated__/graphql';
import {
  MeasurementLinesProps,
  MeasurementLineStyle as ViewerMeasurementLineStyle,
} from '@abyss/3d-viewer';
import * as THREE from 'three';
import { DEFAULT_MEASURMENT_LINE_STYLES } from '../constants';

export const poiLineMeasurmentToProps = (
  lineMeasurmentFromDatabase: PoiLineMeasurement | undefined
): MeasurementLinesProps | undefined => {
  if (!lineMeasurmentFromDatabase) {
    return undefined;
  }

  const pointsVector: THREE.Vector3[] = lineMeasurmentFromDatabase.points.map(
    (point) => new THREE.Vector3(point[0], point[1], point[2])
  );

  return {
    name: 'poi.lineMeasurement',
    points: pointsVector,
    styles: (lineMeasurmentFromDatabase.styles ||
      DEFAULT_MEASURMENT_LINE_STYLES) as ViewerMeasurementLineStyle,
  };
};

export const propsToPoiLineMeasurement = (
  props: MeasurementLinesProps | undefined
): PoiLineMeasurement | undefined => {
  if (!props?.points) {
    return undefined;
  }

  const points = props.points.map((point) => [point.x, point.y, point.z]);
  return {
    points,
    styles: props.styles as MeasurementLineStyle | undefined,
  };
};

/**
 * Checks if the given POI (Point of Interest) type is compatible with the measurement tool. 
 * Right now this is hardcoded to only work with PAINT_DAMAGE POIs.
 * In the (very) near future this check would come from the template of the POI
 *
 * @param poiType - The type of the POI to check for compatibility.
 * @returns `true` if the POI type is compatible with the measurement tool, otherwise `false`.
 */
export const isPoiMeasurementToolCompatible = (poiType: string): boolean => {
  const compatibleTypes = ['PAINT_DAMAGE'];
  return compatibleTypes.includes(poiType);
};
