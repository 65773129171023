import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import {
  useSavePointOfInterestScreenshotMutation,
  ScreenshotTypeEnum,
  FileAttributes,
  useUpdatePointOfInterestFileMutation,
  FileUploadStatus,
} from '@/__generated__/graphql';
import { useUploadAttachmentsToPoiMutation } from '@/__generated__/graphql';
import { Alert } from '@mui/material';
import { AllPointsOfInterestForInspectionDataLoaderDocument } from '@/__generated__/graphql';
import {
  getSizeFromBase64,
  uploadBase64ToUrl,
} from '../PointOfInterestTab/SelectedPoiContent/Tabs/UploadFileToUrl';
import { PointOfInterestDocument } from '@/__generated__/graphql';

type SaveScreenshotProps = {
  location?: 'screenshots' | 'attachments';
  poiId: string | undefined;
  setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdating?: boolean;
  refetchPoi?: boolean;
};

export const useSavePoiScreenshot = () => {
  const selectedSpherical = useRecoilState(state.selectedSpherical);
  const callbacks = useRecoilValue(state.globalCallbacks);
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);

  const [uploadAttachmentsToPoi] = useUploadAttachmentsToPoiMutation();
  const [updatePoiFile] = useUpdatePointOfInterestFileMutation();

  const [savePointOfInterestScreenshot] = useSavePointOfInterestScreenshotMutation();
  const setAreSphericalsVisible = useSetRecoilState(state.areSphericalsVisible);
  const setShowPointCloudInSpherical = useSetRecoilState(state.showPointCloudInSpherical);
  const setArePoisVisible = useSetRecoilState(state.arePoisVisible);
  const currentSpherical = useRecoilValue(state.viewerCurrentSpherical);

  const getViewpointScreenshot = useCallback(() => {
    /* Use This Method to have blank/white background */

    // let screenshot: string = '';
    // if (callbacks?.takeScreenShot) {
    //   screenshot = callbacks.takeScreenShot()?.toString() ?? '';
    // }
    // return screenshot;

    /* Use This Method to set background to whatever you want */
    const htmlCanvas = callbacks?.getHTMLCanvas && callbacks.getHTMLCanvas();

    if (htmlCanvas === undefined) {
      return;
    }
    const canvas2 = document.createElement('canvas');
    canvas2.width = htmlCanvas.width;
    canvas2.height = htmlCanvas.height;

    const context2 = canvas2.getContext('2d');
    if (!context2) {
      return;
    }

    // fill background
    context2.fillStyle = '#283237';
    context2.fillRect(0, 0, canvas2.width, canvas2.height);
    context2.drawImage(htmlCanvas, 0, 0);

    const imgData = canvas2.toDataURL();
    return imgData;
  }, [callbacks]);

  const handleSavePoiScreenshot = useCallback(
    async ({
      location = 'screenshots',
      poiId,
      isUpdating,
      setIsUpdating,
      refetchPoi,
    }: SaveScreenshotProps) => {
      if (isUpdating) {
        return setSnackbarMessage({
          shouldShow: true,
          content: (
            <Alert severity="warning">
              Unable to take Screenshot at the moment. Other files are uploading. Please try once
              uploading is done
            </Alert>
          ),
        });
      } else {
        if (setIsUpdating) setIsUpdating(true);
        const viewpointScreenshot = getViewpointScreenshot();
        if (!viewpointScreenshot || !poiId) {
          return '';
        }

        if (currentSpherical) {
          setShowPointCloudInSpherical(true);
          setArePoisVisible(true);
        }

        //enable viewpoints
        setAreSphericalsVisible(true);

        if (location === 'screenshots') {
          const type = selectedSpherical ? ScreenshotTypeEnum.Viewpoint : ScreenshotTypeEnum.Viewer;
          return savePointOfInterestScreenshot({
            variables: {
              input: {
                pointOfInterestId: poiId,
                type,
                image: viewpointScreenshot,
                lastModifiedFor: 'Save Screenshot',
              },
            },
            ...(refetchPoi ? { refetchQueries: [PointOfInterestDocument] } : {}),
          });
        } else if (location === 'attachments') {
          const screenshotFile: FileAttributes = {
            uuid: `file-${Date.now()}`,
            name: `viewer-screenshot-${Date.now()}.png`,
            mimeType: 'image/png',
            size: getSizeFromBase64(viewpointScreenshot),
          };
          const { data } = await uploadAttachmentsToPoi({
            variables: {
              input: {
                pointOfInterestId: poiId,
                files: [screenshotFile],
                lastModifiedFor: 'Capture View',
              },
            },
            refetchQueries: [AllPointsOfInterestForInspectionDataLoaderDocument],
            onError: (error) => {
              setSnackbarMessage({
                shouldShow: true,
                content: <Alert severity="error">{error.message}</Alert>,
              });
            },
          });
          if (data?.uploadAttachmentsToPoi && data?.uploadAttachmentsToPoi?.length > 0) {
            const file = data.uploadAttachmentsToPoi[0];
            const uploadedFile = await uploadBase64ToUrl(viewpointScreenshot, file.url);
            if (uploadedFile)
              updatePoiFile({
                variables: {
                  input: {
                    pointOfInterestId: poiId,
                    fileId: file.id,
                    uploadStatus: FileUploadStatus.Success,
                  },
                },
                refetchQueries: [PointOfInterestDocument],
                onCompleted: () => {
                  setSnackbarMessage({
                    shouldShow: true,
                    content: (
                      <Alert severity="success">Screenshot has been successfully uploaded</Alert>
                    ),
                  });
                },
                onError: (error) => {
                  if (setIsUpdating) setIsUpdating(false);
                  setSnackbarMessage({
                    shouldShow: true,
                    content: <Alert severity="error">{error.message}</Alert>,
                  });
                },
              });
            if (setIsUpdating) setIsUpdating(false);
          }
        }
      }
    },
    [
      getViewpointScreenshot,
      currentSpherical,
      setShowPointCloudInSpherical,
      setAreSphericalsVisible,
      setArePoisVisible,
      selectedSpherical,
      setSnackbarMessage,
      savePointOfInterestScreenshot,
      uploadAttachmentsToPoi,
      updatePoiFile,
    ]
  );

  return { handleSavePoiScreenshot };
};
