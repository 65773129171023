import { Box, Button, IconButton, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useMemo } from 'react';
import { CustomTextField } from '@/components/shared/CustomField';
import { formatTemplateFieldValue } from '@/utils/unitSystem';
import { unitSystem as unitSystemState } from '@/components/Analysis/state';
import { TemplateFieldTypeEnum } from '@/types';
import { containedButtonStyle } from '@/theme';
import { FilterCenterFocus as AddLocationIcon } from '@mui/icons-material';
import { UPDATE_LOCATION_LABEL } from '@/constants';
import {
  cancelPOIAdd as cancelPOIAddState,
  editPointOfInterest as editPointOfInterestState,
} from '../../../state';
import CancelIcon from '@mui/icons-material/Cancel';
import { abyssColors } from '@/theme/colors';

type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

type Props = {
  fields: Array<{
    label: string;
    value: string;
    type?: TemplateFieldTypeEnum;
    valueTooltip?: string;
    onClick?: (event: undefined | ClickEvent) => void;
  }>;
  sx?: SxProps;
};

const linkFields = new Set(['Image Name']);
const truncatableFields = new Set(['Comment', 'Accessibility']);

export const FieldViewer = ({ fields, sx }: Props) => {
  const unitSystem = useRecoilValue(unitSystemState);
  const editPointOfInterest = useRecoilValue(editPointOfInterestState);
  const cancelPOIAdd = useSetRecoilState(cancelPOIAddState);

  const formatFieldValue = useCallback(
    (type: TemplateFieldTypeEnum | undefined, value: string) => {
      return formatTemplateFieldValue(type, value, unitSystem);
    },
    [unitSystem]
  );

  const isUpdatingLocation = useMemo(() => {
    return editPointOfInterest.formState === 'Update-Location';
  }, [editPointOfInterest.formState]);

  const cancelLocationUpdate = useCallback(() => {
    cancelPOIAdd(undefined);
  }, [cancelPOIAdd]);

  return (
    <Stack sx={sx} spacing={1}>
      {(fields || []).map(({ label, value, type, valueTooltip, onClick }) => {
        const truncate = truncatableFields.has(label) ? { cutoff: 'dynamic' as const } : undefined;

        if (label === UPDATE_LOCATION_LABEL) {
          return (
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ fontSize: '1.4rem' }}>{UPDATE_LOCATION_LABEL}</Typography>
              <Tooltip title="Ctrl + Click to open in new tab" placement="top" arrow>
                <Box>
                  <Button
                    key={label}
                    startIcon={<AddLocationIcon />}
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ ...containedButtonStyle, height: 26, px: 0.7 }}
                    onClick={onClick}
                    disabled={isUpdatingLocation}
                  >
                    {value === 'true' ? 'Update Location' : 'Add Location'}
                  </Button>
                  {isUpdatingLocation && (
                    <Tooltip title="Cancel Location Update" arrow placement="top">
                      <IconButton
                        sx={{ p: 0, border: 1, ml: 0.5, borderRadius: '4px' }}
                        onClick={cancelLocationUpdate}
                      >
                        <CancelIcon sx={{ color: abyssColors.primary[500] }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Tooltip>
            </Stack>
          );
        }

        return (
          <CustomTextField
            key={label}
            mode="ReadOnly"
            id={label}
            title={label}
            valueTooltip={valueTooltip}
            value={formatFieldValue(type, value)}
            onClick={onClick}
            underlined={linkFields.has(label) && value !== 'N/A'}
            truncate={truncate}
            sx={{ color: abyssColors.primary[400] }}
          />
        );
      })}
    </Stack>
  );
};
