import { Typography, Box, Tooltip } from '@mui/material';
import { useMemo, useRef } from 'react';
import { stringFromDateOrString } from './utils';
import { ReadModeProps } from './types';
import { theme } from '@/theme/theme';

const boxRootStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const DEFAULT_CUTOFF = 30;

export const ReadOnlyTemplateField = ({
  value,
  title,
  underlined,
  sx: styles,
  truncate,
  valueTooltip,
  ...props
}: ReadModeProps) => {
  const stringifiedValue = stringFromDateOrString(value) ?? 'N/A';

  const reference = useRef<HTMLDivElement | null>(null);

  const elementWidth = useMemo(() => reference.current?.offsetWidth, [reference]);

  const truncatedValue = useMemo(() => {
    if (!truncate || !truncate.cutoff) return stringifiedValue;

    if (typeof truncate.cutoff === 'number') {
      return stringifiedValue.slice(0, truncate.cutoff);
    }
    return `${stringifiedValue.slice(0, elementWidth ? elementWidth / 10 : DEFAULT_CUTOFF)} ...`;
  }, [elementWidth, stringifiedValue, truncate]);

  const { onClick, ...divProps } = props;
  return (
    <Box ref={reference} sx={{ ...boxRootStyle, ...styles }} {...divProps}>
      <Typography fontSize="1.2rem" fontWeight="500">
        {title}
      </Typography>
      <Tooltip title={valueTooltip} placement="top" arrow>
        <Typography
          fontSize="1.4rem"
          style={{
            textAlign: 'right',
            fontWeight: 900,
            textDecoration: underlined ? 'underline' : '',
            cursor: underlined ? 'pointer' : 'default',
            width: '65%',
            wordBreak: 'break-all',
            color: underlined ? theme.palette.common.darkBlue : theme.palette.text.primary,
          }}
        >
          {truncatedValue}
        </Typography>
      </Tooltip>
    </Box>
  );
};
