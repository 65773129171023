import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getGoverningValueCalculations } from '@/utils';
import { MeasureBlisterQuery, useGetAssemblyPipeSpecByIdLazyQuery } from '@/__generated__/graphql';
import { Point } from '../../../createOrEdit/BlisterMeasurement/Graph';
import { BlisterInput, UnitSystemEnum, useStructureForBlisterQuery } from '@/__generated__/graphql';
import * as localState from '@/components/Analysis/state';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import { MILLIMETERS_TO_METERS_FACTOR, METERS_TO_INCHES_FACTOR } from '@/utils/unitSystem';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { PolygonProps } from '@abyss/3d-viewer';
import { PIT_TYPE } from '@/constants';

const defaultBlisterData: BlisterInput = {
  maxHeight: 0,
  estimatedHeight: 0,
  manualSetHeight: 0,
  radius: 0,
  fittingRadius: 0,
  borders: [],
  borders3d: [],
  centerPoint: { bearing: 0, elevation: 0 },
  radiusPoint: { bearing: 0, elevation: 0 },
  scCategory: '',
};

export type BlisterFieldsData = {
  estimatedHeight?: string;
  manualSetHeight?: string;
  userManualSetHeight?: string;
  scCategory?: string;
};

// Get SC category based on maxHeight of blister, the same logic is being used in backend
const getScCategory = (type: string, maxHeight: number | undefined) => {
  if (!maxHeight) return 'SC0';

  const categories =
    type === PIT_TYPE
      ? [
          { limit: 0.5, category: 'SC1' }, // MBH <= 0.5mm
          { limit: 3, category: 'SC2' }, // 0.5mm < MBH <= 3mm
          { limit: Number.POSITIVE_INFINITY, category: 'SC3' }, // MBH > 3mm
        ]
      : [
          { limit: 2, category: 'SC1' },
          { limit: 5, category: 'SC2' },
          { limit: Number.POSITIVE_INFINITY, category: 'SC3' },
        ];

  const heightInMM = Number(maxHeight) * 1000; // Convert meters to millimeters
  return categories.find(({ limit }) => heightInMM <= limit)?.category ?? 'SC0';
};

export const useBlisterData = (type?: string) => {
  const [editPointOfInterest, setEditPointOfInterest] = useRecoilState(
    poiState.editPointOfInterest
  );
  const pointOfInterest = editPointOfInterest?.pointOfInterest;
  const [localBlisterData, setLocalBlisterData] = useState<BlisterInput | undefined>(undefined);
  const [blisterFieldsData, setBlisterFieldsData] = useState<BlisterFieldsData | undefined>(
    undefined
  );
  const [pointSelectMessage, setPointSelectMessage] = useState<boolean>(false);
  const selectedAssemblyId = useRecoilValue(localState.selectedAssemblyId);
  const unitSystem = useRecoilValue(localState.unitSystem);
  const setManualBlisterHeightExists = useSetRecoilState(poiState.manualBlisterHeightExists);
  const setBlisterPolys = useSetRecoilState(poiState.blisterPolygons);
  const structureId = useGetStructureId();
  const { data } = useStructureForBlisterQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'cache-first',
  });

  const wallLossFactor = data?.structure?.wallLossFactor;
  const scCategoryMapping = data?.structure?.scCategoryMapping;

  const [getAssemblySpecs, { data: assemblyPipeData }] = useGetAssemblyPipeSpecByIdLazyQuery({
    variables: {
      assemblyId: selectedAssemblyId,
    },
  });

  const { nominalCorrosionAllowance, nominalThickness } = useMemo(() => {
    return {
      nominalCorrosionAllowance:
        assemblyPipeData?.assembly?.pipeSpec?.nominalCorrosionAllowance ?? 0,
      nominalThickness: assemblyPipeData?.assembly?.pipeSpec?.nominalThickness ?? 0,
    };
  }, [assemblyPipeData]);

  useEffect(() => {
    if (pointOfInterest?.blister) {
      getAssemblySpecs();

      setLocalBlisterData({
        ...defaultBlisterData,
        ...pointOfInterest.blister,
      });

      const { estimatedHeight, manualSetHeight, scCategory } = pointOfInterest?.blister || {};

      setBlisterFieldsData((previous) => ({
        ...previous,
        ...(estimatedHeight ? { estimatedHeight: String(estimatedHeight) } : {}),
        ...(manualSetHeight ? { manualSetHeight: String(manualSetHeight) } : {}),
        ...(scCategoryMapping ? { scCategory: scCategory ?? 'SC1' } : ''),
      }));
    }
  }, [getAssemblySpecs, pointOfInterest?.blister, scCategoryMapping]);

  const onMeasure = useCallback(
    (blisterData: MeasureBlisterQuery | undefined) => {
      if (!blisterData) return;
      const { blister, status } = blisterData?.fetchBlisterHeightMeasurement || {};

      const fittingRadius = status?.fittingRadius || 0;
      const estimatedHeight = blister?.maxHeight || 0;
      const borders3d = (blister?.borders3d ?? []).map((border3d) => ({
        points: border3d.points.map(({ x, y, z }) => ({ x, y, z })),
      }));

      setEditPointOfInterest((current) => {
        const { pointOfInterest: poi } = current;
        const centerPoint3d = blister?.centerPoint3d;
        if (!poi || !centerPoint3d) return current;
        const { x, y, z } = centerPoint3d;
        return {
          ...current,
          pointOfInterest: {
            ...poi,
            centerPoint3d: { x, y, z },
          },
          hasBlisterMeasurement: true,
        };
      });

      const governingValues = getGoverningValueCalculations(
        type,
        estimatedHeight,
        nominalCorrosionAllowance,
        nominalThickness,
        wallLossFactor
      );

      const isManualHeightPresent =
        blisterFieldsData?.manualSetHeight || blisterFieldsData?.userManualSetHeight;

      setLocalBlisterData((previous) => ({
        ...defaultBlisterData,
        ...previous,
        ...(isManualHeightPresent ? {} : { ...governingValues, maxHeight: estimatedHeight }),
        estimatedHeight,
        fittingRadius,
        borders3d,
      }));

      setBlisterFieldsData((current) => ({
        ...current,
        estimatedHeight: String(estimatedHeight),
      }));
      if (borders3d.length > 0) {
        const newBlisterPolygon: PolygonProps = {
          id: pointOfInterest?.id,
          borderPoints: borders3d[0].points,
          isVisible: true,
          onClicked: () => {},
          name: 'newBlister',
        };
        setBlisterPolys((previous) => {
          if (!previous) return undefined;
          if (previous.some((polygon) => polygon?.id === pointOfInterest?.id)) return previous;
          return [...previous, newBlisterPolygon];
        });
      }
    },
    [
      setEditPointOfInterest,
      type,
      nominalCorrosionAllowance,
      nominalThickness,
      wallLossFactor,
      blisterFieldsData?.manualSetHeight,
      blisterFieldsData?.userManualSetHeight,
      pointOfInterest?.id,
      setBlisterPolys,
    ]
  );

  const updatePoiBlister = useCallback(
    (blisterHeight: number, shouldConvertHeight: boolean) => {
      const convertHeight = (height: number) => {
        return unitSystem === UnitSystemEnum.Imperial
          ? height / METERS_TO_INCHES_FACTOR
          : height * MILLIMETERS_TO_METERS_FACTOR;
      };

      const convertedBlisterHeight = shouldConvertHeight
        ? convertHeight(blisterHeight)
        : blisterHeight;

      const governingValues = getGoverningValueCalculations(
        type,
        convertedBlisterHeight,
        nominalCorrosionAllowance,
        nominalThickness,
        wallLossFactor
      );

      setLocalBlisterData((previous) => ({
        ...defaultBlisterData,
        ...previous,
        ...governingValues,
        manualSetHeight: convertedBlisterHeight,
        maxHeight: convertedBlisterHeight,
      }));
    },
    [nominalCorrosionAllowance, nominalThickness, type, unitSystem, wallLossFactor]
  );

  const onSelectPoint = useCallback(
    (point: Point) => {
      if (!pointSelectMessage) return;
      setPointSelectMessage(false);
      setBlisterFieldsData((current) => ({
        ...current,
        manualSetHeight: String(point.height),
        userManualSetHeight: '',
      }));
      updatePoiBlister(point.height, false);
    },
    [pointSelectMessage, updatePoiBlister]
  );

  const clearBlisterData = useCallback(() => {
    setLocalBlisterData((previous) => {
      const radiusPoint = previous?.radiusPoint || defaultBlisterData.radiusPoint;
      const centerPoint = previous?.centerPoint || defaultBlisterData.centerPoint;
      return {
        ...defaultBlisterData,
        radiusPoint,
        centerPoint,
        scCategory: scCategoryMapping ? 'SC1' : '',
      };
    });
    setBlisterFieldsData(undefined);
  }, [scCategoryMapping]);

  const handleMeasureIconClick = useCallback(() => {
    setPointSelectMessage((previous) => !previous);
  }, [setPointSelectMessage]);

  const handleManualHeightChange = useCallback(
    (height: string) => {
      if (!height) {
        if (localBlisterData?.estimatedHeight) {
          const { estimatedHeight } = localBlisterData;
          const governingValues = getGoverningValueCalculations(
            type,
            estimatedHeight,
            nominalCorrosionAllowance,
            nominalThickness,
            wallLossFactor
          );

          setLocalBlisterData((previous) => ({
            ...defaultBlisterData,
            ...previous,
            ...governingValues,
            maxHeight: estimatedHeight,
            manualSetHeight: 0,
          }));
        } else {
          clearBlisterData();
        }
        setManualBlisterHeightExists(false);
        setBlisterFieldsData((current) => ({
          ...current,
          manualSetHeight: '',
          userManualSetHeight: '',
        }));
        return;
      }

      setBlisterFieldsData((current) => ({
        ...current,
        manualSetHeight: '',
        userManualSetHeight: height,
      }));
      setManualBlisterHeightExists(true);
      updatePoiBlister(Number(height), true);
    },
    [
      clearBlisterData,
      localBlisterData,
      nominalCorrosionAllowance,
      nominalThickness,
      type,
      updatePoiBlister,
      wallLossFactor,
      setManualBlisterHeightExists,
    ]
  );

  const handleChangeScCategory = useCallback(
    (scCategory: string) => {
      setBlisterFieldsData((current) => ({
        ...current,
        ...(scCategoryMapping ? { scCategory: scCategory ?? 'SC1' } : ''),
      }));
      setLocalBlisterData((current) => ({
        ...defaultBlisterData,
        ...current,
        ...(scCategoryMapping ? { scCategory: scCategory ?? 'SC1' } : ''),
      }));
    },
    [scCategoryMapping]
  );

  useEffect(() => {
    if (editPointOfInterest.formState === 'Update-Template') return;
    const blisterMaxHeight = localBlisterData?.maxHeight;
    if (blisterMaxHeight && type) {
      const scCategory = scCategoryMapping ? getScCategory(type, blisterMaxHeight) : '';
      setBlisterFieldsData((previous) => ({
        ...previous,
        scCategory,
      }));
      setLocalBlisterData((current) => ({
        ...defaultBlisterData,
        ...current,
        scCategory,
      }));
    }
  }, [editPointOfInterest.formState, localBlisterData?.maxHeight, scCategoryMapping, type]);

  useEffect(() => {
    if (localBlisterData?.manualSetHeight) {
      const manualHeight = localBlisterData?.manualSetHeight;
      const governingValues = getGoverningValueCalculations(
        type,
        manualHeight,
        nominalCorrosionAllowance,
        nominalThickness,
        wallLossFactor
      );

      setLocalBlisterData((previous) => ({
        ...defaultBlisterData,
        ...previous,
        ...governingValues,
        manualSetHeight: manualHeight,
        maxHeight: manualHeight,
      }));
    }
  }, [
    nominalCorrosionAllowance,
    nominalThickness,
    type,
    wallLossFactor,
    localBlisterData?.manualSetHeight,
  ]);

  return {
    onMeasure,
    pointSelectMessage,
    handleManualHeightChange,
    onSelectPoint,
    clearBlisterData,
    handleMeasureIconClick,
    handleChangeScCategory,
    localBlisterData,
    blisterFieldsData,
    updatePoiBlister,
  };
};
