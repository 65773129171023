import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteChangeReason, TextField, Alert, Stack } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useUser } from '@auth0/nextjs-auth0';
import * as Sentry from '@sentry/nextjs';
import { useHavePermission } from '@/hooks';
import {
  useAddNewAssemblyTagNameMutation,
  useAddPartsToAssemblyInputForDeletePartMutation,
} from '@/__generated__/graphql';
import * as state from '@/state';
import { Permissions, type PartSummary, type StructureRelationships } from '@/types';
import { Container } from './styles';

import { PartHistory } from './PartHistory';
import { NO_TAG_ASSEMBLY_NAME, NO_TAG_ASSEMBLY_DISPLAY_NAME } from '@/constants';

type Props = {
  selectedParts: PartSummary[];
};

const getOptionGroup = (option: { id: string; tagName: string; isNewlyAdded?: boolean }) => {
  return option.isNewlyAdded ? 'Newly Added' : 'Predefined';
};

const getOptionLabel = (option: { id: string; tagName: string; isNewlyAdded?: boolean }) => {
  return option.tagName === NO_TAG_ASSEMBLY_NAME ? NO_TAG_ASSEMBLY_DISPLAY_NAME : option.tagName;
};

export const PartTag = ({ selectedParts }: Props) => {
  const { user } = useUser();

  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const selectedStructureId = useRecoilValue(state.selectedStructureId);
  const setStructureRelationships = useSetRecoilState(state.structureRelationships);
  const [error, setError] = useState(false);

  const [selectedAssembly, setSelectedAssembly] = useState<{
    id: string;
    tagName: string;
    isNewlyAdded?: boolean;
  }>();
  const canAccessAddNewTag = useHavePermission(Permissions.CREATE_ASSEMBLY);
  const allAssemblies = useRecoilValue(state.allAssemblies);
  const [addPartsToAssembly] = useAddPartsToAssemblyInputForDeletePartMutation();
  const [addNewAssemblyTagName] = useAddNewAssemblyTagNameMutation();
  const selectedZone = useRecoilValue(state.selectedZone);

  useEffect(() => {
    const selectedAssemblyIds = selectedParts.map((part) => part.assemblyId);
    const uniqueAssemblyIds = [...new Set(selectedAssemblyIds)];
    if (uniqueAssemblyIds.length === 1) {
      // only 1 assembly is selected
      const id = selectedParts[0].assemblyId;
      if (id) {
        setSelectedAssembly((currentSelectedAssembly) => {
          let tagName: string | undefined = '';
          if (
            currentSelectedAssembly &&
            currentSelectedAssembly.id === selectedParts[0].assemblyId
          ) {
            tagName =
              currentSelectedAssembly.tagName === NO_TAG_ASSEMBLY_NAME
                ? NO_TAG_ASSEMBLY_DISPLAY_NAME
                : currentSelectedAssembly.tagName;
          } else {
            tagName =
              selectedParts[0].assemblyTagName === NO_TAG_ASSEMBLY_NAME
                ? NO_TAG_ASSEMBLY_DISPLAY_NAME
                : selectedParts[0].assemblyTagName;
          }
          return { id, tagName: tagName! };
        });
      } else {
        setSelectedAssembly(undefined);
      }
    } else {
      // multiple is selected
      setSelectedAssembly({
        id: 'multiple',
        tagName: 'Multiple',
      });
    }
  }, [selectedParts]);

  const partAssemblyChanged = async (
    _event: SyntheticEvent,
    value: { id: string; tagName: string; isNewlyAdded?: boolean } | null,
    reason: AutocompleteChangeReason
  ) => {
    if (
      reason === 'selectOption' &&
      selectedParts.every((selectedPart) => selectedPart.id !== undefined) &&
      value?.tagName &&
      !error
    ) {
      if (!selectedStructureId) {
        setSnackbarMessage({
          shouldShow: true,
          content: <Alert severity="error">Error updating part: structureId not set...</Alert>,
        });
        return;
      }
      let assemblyId: string | undefined = value?.id || undefined;

      setSnackbarMessage({
        shouldShow: true,
        content: <Alert severity="info">Updating Equipment Tag</Alert>,
      });

      const tagName = value.tagName.replace(/^Add\s+|["']/g, '')?.toUpperCase();
      // check whether the assembly isNewlyAdded flag is true and the input tagname is not present in the allAssemblies
      if (
        value?.isNewlyAdded &&
        allAssemblies.filter((assembly) =>
          assembly.tagName!.toLowerCase().includes(value.tagName.toLowerCase())
        ).length === 0
      ) {
        const assemblyAdded = await addNewAssemblyTagName({
          variables: {
            input: {
              tagName,
              structureId: selectedStructureId,
              lastModifiedFor: 'Add new assembly tag name',
            },
          },
        });
        assemblyId = assemblyAdded.data?.addNewAssemblyTagName?.id;
      }

      if (assemblyId) {
        setSelectedAssembly({
          ...value,
          id: assemblyId,
          tagName: value.isNewlyAdded ? value.tagName : tagName,
        });
        let input = {
          partIds: selectedParts.map((selectedPart) => selectedPart.id),
          assemblyId,
          structureId: selectedStructureId,
        };
        if (selectedZone?.id) {
          const zone = { zoneId: selectedZone?.id };
          input = { ...input, ...zone };
        }

        const { data: updatedPartsData } = await addPartsToAssembly({
          variables: {
            input,
          },
        });

        setStructureRelationships((currentRelationships) => {
          if (!currentRelationships) {
            return currentRelationships;
          }

          const byAnnotationId = currentRelationships.byAnnotationId.asMutableStateMap();
          const byPartId = currentRelationships.byPartId.asMutableStateMap();
          const byAssemblyId = currentRelationships.byAssemblyId.asMutableStateMap();

          updatedPartsData?.addPartsToAssembly.forEach((entry) => {
            const oldPart = byPartId.get(entry.id);
            if (oldPart) {
              const part = { ...oldPart };
              part.assemblyId = entry.assembly?.id;
              byPartId.set(entry.id, part);
            } else {
              Sentry.captureMessage(`Part does not exist in local state`, {
                user: { sub: user?.sub },
                extra: { partId: entry.id },
              });
            }
          });

          const updatedRelationships: StructureRelationships = {
            ...currentRelationships,
            byAnnotationId: byAnnotationId.asStateMap(),
            byPartId: byPartId.asStateMap(),
            byAssemblyId: byAssemblyId.asStateMap(),
          };

          return updatedRelationships;
        });

        if (updatedPartsData?.addPartsToAssembly[0]?.updatedBy === user?.sub) {
          const message =
            !value.tagName || value.tagName === NO_TAG_ASSEMBLY_NAME
              ? 'Removed equipment tag'
              : `Updated equipment tag to ${value.tagName}`;
          setSnackbarMessage({
            shouldShow: true,
            content: <Alert severity="success">{message}</Alert>,
          });
        }
      }
    }
  };
  const handleInputChange = (_event: SyntheticEvent, newInputValue: string) => {
    if (selectedAssembly) {
      setSelectedAssembly({ id: selectedAssembly.id, tagName: newInputValue });
    }
    // below regex validates: only uppercase, lowercase letters, integers, -, #, ?, _ are allowed
    const validRegex = /^[\w#./?-]+$/;
    // this regex will remove the initial ADD "" for newly Added TagName
    if (validRegex.test(newInputValue.replace(/^Add\s+|["']/g, ''))) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleFilterOptions = (
    options: {
      id: string;
      tagName: string;
      isNewlyAdded?: boolean;
    }[],
    { inputValue }: { inputValue: string }
  ) => {
    let exactMatch = false;
    const filteredOptions = options.filter(({ tagName }) => {
      if (tagName.toLowerCase() === inputValue.toLowerCase()) {
        exactMatch = true;
      }
      return tagName.toLowerCase().includes(inputValue.toLowerCase());
    });

    // If there are no matching options and the user has typed something,
    // show an "Add" option
    if (canAccessAddNewTag && !exactMatch && inputValue !== '') {
      filteredOptions.push({
        tagName: `Add "${inputValue}"`,
        id: '',
        isNewlyAdded: true,
      });
    }
    return filteredOptions;
  };

  return (
    <>
      {allAssemblies && selectedAssembly && (
        <Container>
          <Stack direction="row">
            <Autocomplete
              fullWidth
              id="part-tag-selector"
              data-testid="autocomplete"
              defaultValue={selectedAssembly}
              value={selectedAssembly}
              isOptionEqualToValue={(option, value) => option.tagName === value.tagName}
              options={allAssemblies || []}
              {...(canAccessAddNewTag && !error ? { filterOptions: handleFilterOptions } : {})}
              groupBy={getOptionGroup}
              getOptionLabel={getOptionLabel}
              disableListWrap
              autoHighlight
              onChange={partAssemblyChanged}
              renderInput={(parameters) => (
                <TextField
                  {...parameters}
                  label="Equipment tag"
                  variant="standard"
                  error={error}
                  sx={{ flexGrow: 1 }}
                  helperText={
                    canAccessAddNewTag
                      ? 'Only uppercase, lowercase letters, integers, -, #, ?, _ are allowed, Invalid characters are [Space “ ,]'
                      : ''
                  }
                />
              )}
              onInputChange={handleInputChange}
            />
            {selectedParts.length === 1 && <PartHistory selectedPart={selectedParts[0]} />}
          </Stack>
        </Container>
      )}
    </>
  );
};
